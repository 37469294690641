import {ActivityStaffingStatusPriority} from "../constants/ActivityStaffingConstants"
import {FileType} from "../constants/FileConstants"
import {StaffedResource} from "../interfaces/ActivityStaffingInterface"
import {OrderItem} from "../interfaces/OrderItemInterfaces"
import {PROFILE} from "../interfaces/UserInterfaces"
import {authUtils} from "./authUtils"

export const sortStaffedResourceByAvailability = (a: StaffedResource, b: StaffedResource): number => {
  if (ActivityStaffingStatusPriority[a.status] < ActivityStaffingStatusPriority[b.status]) {
      return -1;
  }
  if (ActivityStaffingStatusPriority[a.status] > ActivityStaffingStatusPriority[b.status]) {
      return 1;
  }
  return 0;
}

export const orderItemNeedsTicket = (orderItem: OrderItem): boolean => {
  const tickets = orderItem?.bookingDocuments?.some(bd => bd.fileType === FileType.TICKET)
  const shouldBeShownToProfile = [PROFILE.CLIENT, PROFILE.ADMIN, PROFILE.INTERN].includes(authUtils.getProfile())
  return orderItem.canAddTicket && !tickets && shouldBeShownToProfile
}


export const orderItemUtils = Object.freeze({
  sortStaffedResourceByAvailability,
  orderItemNeedsTicket,
});
