import {httpService} from "./HttpService";
import {API_IMPORT_TEMPLATE_PATH} from "../constants/routes/RoutePaths";
import {ImportTemplateType} from "../interfaces/TemplateInterfaces";

const getTemplateCsv = (template: ImportTemplateType): Promise<Blob> => {
  return httpService.getReadableStream(`${API_IMPORT_TEMPLATE_PATH}?importType=${template}`);
}

export const templateService = {
  getTemplateCsv
}
