import React, {FunctionComponent} from "react";
import {ActivityWithUsageSelecteble} from "../../../../interfaces/ActivityInterfaces";
import {Col, Input} from "reactstrap";
import {moneyUtils} from "../../../../utils/moneyUtils";
import {OrderItemDefaultSelectable} from "../../../../interfaces/OrderItemInterfaces";
import {ORDERS_PATH} from "../../../../constants/routes/RoutePaths";
import {orderUtils} from "../../../../utils/orderUtils";
import TextLink from "../../../atoms/TextLink";

interface OrderItemsFormTableRowProps {
  activity: ActivityWithUsageSelecteble,
  isSelected: boolean,
  handleValueChange: (activity: ActivityWithUsageSelecteble) => void,
  index: number
  rowOpen: {[index: string]: boolean},
  setRowOpen: (activityId: {[index: string]: boolean}) => void,
  activitiesSelectable: ActivityWithUsageSelecteble[]
  setActivitiesSelectable: (activities: ActivityWithUsageSelecteble[]) => void
}

const OrderItemsFormTableRow: FunctionComponent<OrderItemsFormTableRowProps> = ({
  activity,
  index,
  rowOpen,
  setRowOpen,
  activitiesSelectable,
  setActivitiesSelectable
}) => {

  const handleOpenRow = (activityId: string) => {
    const isRowCurrentlyOpen = rowOpen[activityId] ?? false

    setRowOpen({
      ...rowOpen,
      [activityId]: !isRowCurrentlyOpen,
    })
  }

  function handleFormValuesChanges(value: ActivityWithUsageSelecteble, checked: boolean) {
    setActivitiesSelectable(activitiesSelectable.map(activity => {
      if (activity.id === value.id) {
        return {
          ...activity,
          isSelected: checked,
          orderItems: activity.orderItems.map(orderItem => ({...orderItem, isSelected: checked}))
        }
      }
      return activity
    }))
  }

  function handleSubItemSelect(value: OrderItemDefaultSelectable, checked: boolean) {
    setActivitiesSelectable(activitiesSelectable.map(activity => {
      if (activity.id === value.activityId) {
        return {
          ...activity,
          orderItems: activity.orderItems.map(orderItem => {
            if (orderItem.id === value.id) {
              return {
                ...orderItem,
                isSelected: checked
              }
            }
            return orderItem
          })
        }
      }
      return activity
    }))
  }

  return (
          <div  className="epow-custom-table-row">
            <div className="epow-custom-table-row-main" onClick={() => handleOpenRow(activity?.id)}>
              <Col xs={1} onClick={(event) => { { event.preventDefault(); event.stopPropagation() } }}>
                <Input
                  className="ms-2"
                  type="checkbox"
                  checked={activity.isSelected}
                  onChange={() => {
                    handleFormValuesChanges(activity, !activity.isSelected)
                  }}
                />
              </Col>
              <Col className="me-3" md={2} onClick={event => event.stopPropagation()}>
                {activity.name}
              </Col>
              <Col md={18}>
                {activity.orderItems[0]?.order?.tourReference || "-"}
              </Col>
              <Col md={2}>
                {activity.orderItems.length} prestation{activity.orderItems.length > 1 && "s"}
              </Col>
            </div>
              {rowOpen[activity?.id] && activity.orderItems.length > 0 &&
                activity.orderItems.map((orderItem, indexOrderItem) => (
                (
                <div className="epow-custom-table-row-details" key={`activity_${index}_orderItem_${indexOrderItem}`}>
                  <div className="d-flex flex-row w-100">
                    <Col xs={1}>
                      <Input
                        className="mx-2"
                        type="checkbox"
                        checked={orderItem.isSelected}
                        onChange={() => {
                          handleSubItemSelect(orderItem, !orderItem.isSelected)
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      {orderItem.name || "-"}
                    </Col>
                    <Col>
                      <TextLink to={`${ORDERS_PATH}/${orderItem.order.id}`}>
                        <>{orderUtils.getOrderRefDisplay(orderItem.order.orderNumber, orderItem.order.customerReference, orderItem.order.customerName)}</>
                      </TextLink>
                    </Col>
                    <Col md={12}>
                      <p><span className="text-decoration-line-through">{orderItem.description || "-"}</span> | <span className="fw-bold">{orderItem.dayByDayDescription || "-"}</span></p>
                    </Col>
                    <Col className="ms-5">
                      <p><span className="text-decoration-line-through">{moneyUtils.formatNumberToCurrency(orderItem.paxPrice) || "-"}</span> | <span className="fw-bold">{moneyUtils.formatNumberToCurrency(orderItem.catalogPrice) || "-"}</span></p>
                    </Col>
                  </div>
                </div>
              )))}
          </div>
  )
}

export default OrderItemsFormTableRow
