
export const enum CatalogImportError {
  UNKNOWN_CUSTOMER = "UNKNOWN_CUSTOMER",
  UNKNOWN_ACTIVITY = "UNKNOWN_ACTIVITY",
  UNKNOWN_CATALOG = "UNKNOWN_CATALOG",
  UNKNOWN_SUPPLIER = "UNKNOWN_SUPPLIER",
  UNKNOWN_SERVICE = "UNKNOWN_SERVICE",
  INVALID_DATE = "INVALID_DATE",
  INVALID_UNIT = "INVALID_UNIT",
  INVALID_PAX = "INVALID_PAX",
  INVALID_PRICE = "INVALID_PRICE",
  INVALID_DESCRIPTION = "INVALID_DESCRIPTION",
}

export interface ImportErrorMap {
  [key: string]: ImportErrorMapValue
}

interface ImportErrorMapValue {
  id: string,
  label: string,
}

export const CatalogImportErrorMap: ImportErrorMap = {
  UNKNOWN_CUSTOMER: {
    id: CatalogImportError.UNKNOWN_CUSTOMER,
    label: "imports_error_unknown_customer",
  },
  UNKNOWN_ACTIVITY: {
    id: CatalogImportError.UNKNOWN_ACTIVITY,
    label: "imports_error_unknown_activity",
  },
  UNKNOWN_CATALOG: {
    id: CatalogImportError.UNKNOWN_CATALOG,
    label: "imports_error_unknown_catalog",
  },
  UNKNOWN_SUPPLIER: {
    id: CatalogImportError.UNKNOWN_SUPPLIER,
    label: "imports_error_unknown_supplier",
  },
  UNKNOWN_SERVICE: {
    id: CatalogImportError.UNKNOWN_SERVICE,
    label: "imports_error_unknown_service",
  },
  INVALID_DATE: {
    id: CatalogImportError.INVALID_DATE,
    label: "imports_error_invalid_date",
  },
  INVALID_UNIT: {
    id: CatalogImportError.INVALID_UNIT,
    label: "imports_error_invalid_unit",
  },
  INVALID_PAX: {
    id: CatalogImportError.INVALID_PAX,
    label: "imports_error_invalid_pax",
  },
  INVALID_PRICE: {
    id: CatalogImportError.INVALID_PRICE,
    label: "imports_error_invalid_price",
  },
  INVALID_DESCRIPTION: {
    id: CatalogImportError.INVALID_DESCRIPTION,
    label: "imports_error_invalid_description",
  },
}
