import {
  API_ACTIVITIES_PATH,
  API_ACTIVITY_RESOURCE_REQUIREMENTS_PATH
} from "../constants/routes/RoutePaths"
import {httpService} from "./HttpService"
import {
  ActivityResourceRequirement,
  ActivityResourceRequirementForm
} from "../interfaces/ActivityResourceRequirementInterfaces";

function getRequirementsForActivity(activityId: string): Promise<ActivityResourceRequirement[]> {
  return httpService.get<ActivityResourceRequirement[]>(`${API_ACTIVITIES_PATH}/${activityId}${API_ACTIVITY_RESOURCE_REQUIREMENTS_PATH}`)
}

function createRequirementForActivity(activityId: string, request: ActivityResourceRequirementForm): Promise<ActivityResourceRequirement> {
  return httpService.post<ActivityResourceRequirement>(`${API_ACTIVITIES_PATH}/${activityId}${API_ACTIVITY_RESOURCE_REQUIREMENTS_PATH}`, request)
}

function updateRequirementForActivity(requirementId: string, activityId: string, request: ActivityResourceRequirementForm): Promise<ActivityResourceRequirement> {
  return httpService.put<ActivityResourceRequirement>(`${API_ACTIVITIES_PATH}/${activityId}${API_ACTIVITY_RESOURCE_REQUIREMENTS_PATH}/${requirementId}`, request)
}

function deleteRequirementForActivity(requirementId: string, activityId: string,): Promise<Response> {
  return httpService.delete(`${API_ACTIVITIES_PATH}/${activityId}${API_ACTIVITY_RESOURCE_REQUIREMENTS_PATH}/${requirementId}`)
}

export const activityResourceRequirementService = Object.freeze({
  getRequirementsForActivity,
  createRequirementForActivity,
  updateRequirementForActivity,
  deleteRequirementForActivity
})
