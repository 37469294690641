import {API_CATALOG_CUSTOMER_SERVICES_PATH} from "../constants/routes/RoutePaths"
import {dateUtils} from "../utils/dateUtils"
import {httpService} from "./HttpService"
import {queryUtils} from "../utils/queryUtils";
import {
  CatalogCustomerServiceCsv,
  CatalogCustomerServiceSearchObject
} from "../interfaces/CatalogCustomerServiceInterfaces";

function getPriceForCustomerService(serviceId: string, customerId: string, date: string): Promise<number> {
  return httpService.get(`${API_CATALOG_CUSTOMER_SERVICES_PATH}/${serviceId}/${customerId}/price?date=${dateUtils.formatDateYYYYMMDD(dateUtils.parseDate(date))}`)
}

function exportCatalogCustomerServiceCsv(params: CatalogCustomerServiceSearchObject): Promise<Blob> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(null, params)
  return httpService.getReadableStream(`${API_CATALOG_CUSTOMER_SERVICES_PATH}/export${queryParams}`)
}

const sendCsv = (file: File): Promise<CatalogCustomerServiceCsv[]> => {
  const formData = new FormData()
  formData.append("file", file)
  return httpService.postFormData<CatalogCustomerServiceCsv[]>(`${API_CATALOG_CUSTOMER_SERVICES_PATH}/csv-import`, formData)
}

function importCatalogCustomerService(catalogCustomerServiceCsv: CatalogCustomerServiceCsv[]): Promise<CatalogCustomerServiceCsv[]> {
  return httpService.post<CatalogCustomerServiceCsv[]>(`${API_CATALOG_CUSTOMER_SERVICES_PATH}/import`, catalogCustomerServiceCsv)
}

export const catalogCustomerServiceService = Object.freeze({
  getPriceForCustomerService,
  exportCatalogCustomerServiceCsv,
  sendCsv,
  importCatalogCustomerService
})
