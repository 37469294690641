import React, {FunctionComponent} from "react";
import {ColorType, ColorTypeValues, IconSizeType} from "types/bootstrap/BootstrapType";
import BackgroundIcon from "../../icon/BackgroundIcon";

interface BackToTopButtonProps {
  className?: string,
  color?: ColorTypeValues,
  scrollToTop: () => void,
  pages: string[]
}

const BackToTopButton: FunctionComponent<BackToTopButtonProps> = ({
  className = "",
  color = ColorType.LIGHT,
  scrollToTop,
  pages
}) => {

  return (
      pages.includes(window.location.pathname) &&
    (
      <div className={`${className} back-to-top-btn cursor-pointer`}>
        <BackgroundIcon
          className="d-flex align-item-center rounded-circle bg-theme-primary border mb-2 mt-1 me-2"
          iconName="ChevronUp"
          size={42}
          color={color}
          iconSize={IconSizeType.SM}
          onClick={scrollToTop}
        />
      </div>
    )
  )
}

export default BackToTopButton;
