import React, {FunctionComponent, useMemo, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {RouteProps} from "react-router-dom";
import useSearchFilter from "../../../hooks/useSearchFilter";
import FilterGroup from "../filters/FilterGroup";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {supplierService} from "../../../services/SupplierService";
import DateFilter from "../filters/DateFilter";
import PagedTableWithRowSelection, {PagedTableRef} from "../table/PagedTableWithRowSelection";
import {Data} from "../../../interfaces/TableInterfaces";
import {BookingSearchObject, BookingTableDto} from "../../../interfaces/BookingInterfaces";
import {tableUtils} from "../../../utils/tableUtils";
import {PURCHASE_BOOKING_TABLE} from "../../../constants/Table";
import {bookingService} from "../../../services/BookingService";
import {PaginationQueryParams} from "../../../interfaces/api/PaginationInterface";
import {Row} from "react-table";
import AutocompleteMultiselect from "../filters/AutocompleteMultiselect";
import ModalModifyBookings from "../modal/ModalModifyBookings";
import {toastUtils} from "../../../utils/toastUtils";

const PurchaseOrdersFormList: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()
  const ref = useRef<PagedTableRef>()

  const [selectedDatas, setSelectedDatas] = useState<BookingTableDto[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)

  const initialSearch: BookingSearchObject = {
    supplierIds: [],
    startDate: "",
    isPaid: false,
    isBilled: false,
    isCatalogPriceDifferent: true,
  }

  const {searchObject, updateSearchField, onReset} = useSearchFilter<BookingSearchObject>(initialSearch)
  const columns = useMemo(() => tableUtils.getColumns(PURCHASE_BOOKING_TABLE(intl)), []);

  const getBookings = (query: PaginationQueryParams, filter: BookingSearchObject) => {
    return bookingService.getBookingsPage(query, filter)
  }

  const onValidate = () => {
    bookingService.updateBookingsMassCatalog(selectedDatas.map(i => i.id))
      .then(() => setOpenModal(false))
      .catch(() => {
        setOpenModal(false)
        toastUtils.errorToast("booking_edit_error")
      })
      .finally(() => onReset())
  }

  const onCancel = () => {
    setOpenModal(false)
  }

  return (
    <div>
      <div>
        <FilterGroup className="mb-2 epow-filter">
          <AutocompleteMultiselect
            classNameLabel="epow-label"
            title={intl.formatMessage({id: "booking_filter_supplier"})}
            values={searchObject.supplierIds}
            onChange={(values) => updateSearchField("supplierIds", values)}
            fetchData={(page, filters) => supplierService.getSuppliersPaginated(page, {
              ...filters
            })}
            filterFieldName="name"
            filterFieldSearch="name"
          />
          <DateFilter
            value={searchObject.startDate}
            className="mx-2"
            onChangeDate={(value) => updateSearchField("startDate", value)}
            title={intl.formatMessage({id: "activity_billing_comment_date"})}
          />
          <div>
            <Button onClick={() => onReset()}
                    color={ColorType.SECONDARY}>
              <FormattedMessage id="header_menu_clear_search"/>
            </Button>
          </div>
        </FilterGroup>
      </div>
      <PagedTableWithRowSelection
        ref={ref}
        columns={columns}
        labelNoResult="activities_billing_no_result"
        className="table"
        filters={searchObject}
        fetchData={getBookings}
        rowSelectActionTitle="purchase_update_orders"
        onRowSelectAction={(selectedRows: Array<Row<Data>>) => {
          setSelectedDatas(selectedRows.map(i => i.original as BookingTableDto).filter(i => i.catalogService))
          setOpenModal(selectedRows.map(i => i.original as BookingTableDto).filter(i => i.catalogService).length > 0)
        }}
        defaultSortBy={[{sortBy: "date"}]}
      />

      <ModalModifyBookings open={openModal} datas={selectedDatas} onValidate={onValidate} onCancel={onCancel} />
    </div>
  )
}

export default PurchaseOrdersFormList;
