import React, {FunctionComponent, useState} from "react";
import {RouteProps} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {fileUtils} from "../../../../../utils/fileUtils";
import {Button, Input, Label} from "reactstrap";
import {dateUtils} from "../../../../../utils/dateUtils";
import {CatalogCustomerServiceCsv} from "../../../../../interfaces/CatalogCustomerServiceInterfaces";
import {toastUtils} from "../../../../../utils/toastUtils";
import {catalogCustomerServiceService} from "../../../../../services/CatalogCustomerServiceService";
import {moneyUtils} from "../../../../../utils/moneyUtils";
import {templateService} from "../../../../../services/TemplateService";
import {ImportTemplateType} from "../../../../../interfaces/TemplateInterfaces";
import {CatalogImportError} from "../../../../../interfaces/ImportInterface";
import {getClassNameByErrorType} from "../../../../../utils/importUtils";

const AdminImportCustomerServiceCatalog: FunctionComponent<RouteProps> = () => {
  const [csvCatalogCustomerService, setCsvCatalogCustomerService] = useState<CatalogCustomerServiceCsv[]>(null)
  const [errorMessages, setErrorMessages] = useState<string[]>([])

  const intl = useIntl()

  const sendCsv = (file: File) => {
    if (file) {
      catalogCustomerServiceService.sendCsv(file).then(response => {
        setCsvCatalogCustomerService(response)
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "imports_csv_error"}))
      })
    }
  }

  const handleCatalogsCsvImport = (e: React.FormEvent<HTMLInputElement>) => {
    const selectedFile = (e.target as HTMLInputElement).files[0];
    sendCsv(selectedFile)
  };

  const sendCatalog = () => {
    catalogCustomerServiceService.importCatalogCustomerService(csvCatalogCustomerService)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "imports_csv_success"}))
      })
      .catch((e) => {
        const messages = e.message.split("\n")
        setErrorMessages(messages)
        toastUtils.errorToast(intl.formatMessage({id: "imports_csv_error"}))
      })
  }

  return (
    <div className="d-flex flex-column mt-5">
      <h2 className="ms-2 bold epow-label">{intl.formatMessage({id: "imports_customer_service_catalog_title"})}</h2>

      <div className="d-flex align-content-center align-self-center col-12 my-4">
        <Input type="file" accept=".csv" onChange={handleCatalogsCsvImport}/>
      </div>

      <div className="epow-text-link fs-6 cursor-pointer " onClick={() => {
        const templateCsv = templateService.getTemplateCsv(ImportTemplateType.TEMPLATE_CUSTOMER_SERVICE_CATALOG);
        fileUtils.downloadFile(templateCsv, {extension: "csv", name: "template_import_customer_service_catalog"}, intl)
      }}>
        <FormattedMessage id="imports_csv_template"/>
      </div>

      {csvCatalogCustomerService && csvCatalogCustomerService.length > 0 && <div className="d-flex flex-column  w-100">

        <div className="d-flex w-100">
          <div className="col-1"></div>
          <Label className="col-4 text-center bold"><FormattedMessage id="imports_customer_service_catalog_customer" /></Label>
          <Label className="col-2 text-center bold"><FormattedMessage id="imports_customer_service_catalog_date" /></Label>
          <Label className="col-4 text-center bold"><FormattedMessage id="imports_customer_service_catalog_service" /></Label>
          <Label className="col-2 text-center bold"><FormattedMessage id="imports_customer_service_catalog_buy_unit" /></Label>
          <Label className="col-2 text-center bold"><FormattedMessage id="imports_customer_service_catalog_buy_price" /></Label>
          <Label className="col-2 text-center bold"><FormattedMessage id="imports_customer_service_catalog_sell_unit" /></Label>
          <Label className="col-2 text-center bold"><FormattedMessage id="imports_customer_service_catalog_sell_price" /></Label>
          <Label className="col-2 text-center bold"><FormattedMessage id="imports_customer_service_catalog_pax" /></Label>
        </div>

        {csvCatalogCustomerService?.map((item, index) =>
          <div key={index} className="d-flex mb-3">
            <div className="col-1 bold">{index}</div>
            <Label className={`col-4 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.UNKNOWN_CUSTOMER)}`}>{item.customerName}</Label>
            <Label className={`col-2 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.INVALID_DATE)}`}>{dateUtils.formatDateDayJs(item.startDateValidity) || "-"}</Label>
            <Label className={`col-4 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.UNKNOWN_SERVICE)}`}>{item.serviceName}</Label>
            <Label className={`col-2 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.INVALID_UNIT)}`}>{item.buyingUnit || "-"}</Label>
            <Label className={`col-2 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.INVALID_PRICE)}`}>{moneyUtils.formatNumberToCurrency(item.buyingPrice)}</Label>
            <Label className={`col-2 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.INVALID_UNIT)}`}>{item.sellingUnit || "-"}</Label>
            <Label className={`col-2 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.INVALID_PRICE)}`}>{moneyUtils.formatNumberToCurrency(item.sellingPrice)}</Label>
            <Label className={`col-2 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.INVALID_PAX)}`}>{item.pax}</Label>
          </div>
        )}

        <div className="d-flex w-100 justify-content-end align-content-center p-3">
          <Button onClick={sendCatalog}>{intl.formatMessage({id: "imports_activity_customer_catalog_customer_create_button"})}</Button>
        </div>
      </div>}
    </div>
  )
}

export default AdminImportCustomerServiceCatalog
