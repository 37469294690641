import React, {FunctionComponent, useMemo} from "react";
import AdminExportCsvCard from "../card/admin/export/AdminExportCsvCard";
import AdminExportPdfCard from "../card/admin/export/AdminExportPdfCard";
import AdminExportServicesCsvCard from "../card/admin/export/AdminExportServicesCsvCard";
import AdminExportBillingZipCard from "../card/admin/export/AdminExportBillingZipCard";
import AdminExportActivityCustomerCatalogCard from "../card/admin/export/AdminExportActivityCustomerCatalogCard";
import AdminExportCatalogSupplierCsvCard from "../card/admin/export/AdminExportCatalogSupplierCsvCard";
import AdminExportCustomerServiceCatalogCard from "../card/admin/export/AdminExportCustomerServiceCatalogCard";
import {useIntl} from "react-intl";
import {ImportType, ImportTypeSearch} from "../../../constants/ImportContants";
import useSearchFilter from "../../../hooks/useSearchFilter";
import AdminImportActivityCustomerCatalog from "../card/admin/import/AdminImportActivityCustomerCatalog";
import AdminImportCustomerServiceCatalog from "../card/admin/import/AdminImportCustomerServiceCatalog";
import AdminImportSupplierServiceCatalog from "../card/admin/import/AdminImportSupplierServiceCatalog";
import ButtonGroup from "../button/ButtonGroup";
import {DropdownItem} from "reactstrap";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import Title from "../../atoms/Title";

interface AdminExportsProps {
  className?: string,
}

const AdminExportsImports: FunctionComponent<AdminExportsProps> = ({
    className,
  }) => {
  const intl = useIntl()

  const initialImportState: ImportTypeSearch  = useMemo(() => ({
    import: null
  }), [])
  const {searchObject, updateSearchField} = useSearchFilter<ImportTypeSearch>(initialImportState)

  const renderImportForm = () => {
    switch (searchObject.import) {
      case ImportType.ACTIVITY_CUSTOMER_CATALOG:
        return <AdminImportActivityCustomerCatalog/>;
      case ImportType.SERVICE_CUSTOMER_CATALOG:
        return <AdminImportCustomerServiceCatalog/>;
      case ImportType.SUPPLIER_SERVICES_CATALOG:
        return <AdminImportSupplierServiceCatalog/>;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <ButtonGroup title="Actions" className={className}>
          <DropdownItem color={ColorType.SECONDARY} className="epow-button me-2"
                        onClick={() => updateSearchField("import", ImportType.ACTIVITY_CUSTOMER_CATALOG)}>
            <div>{intl.formatMessage({id: "imports_activity_customer_catalog"})}</div>
          </DropdownItem>
          <DropdownItem color={ColorType.SECONDARY} className="epow-button me-2"
                        onClick={() => updateSearchField("import", ImportType.SERVICE_CUSTOMER_CATALOG)}>
            <div>{intl.formatMessage({id: "imports_service_customer_catalog"})}</div>
          </DropdownItem>
          <DropdownItem color={ColorType.SECONDARY} className="epow-button me-2"
                        onClick={() => updateSearchField("import", ImportType.SUPPLIER_SERVICES_CATALOG)}>
            <div>{intl.formatMessage({id: "imports_supplier_service_catalog"})}</div>
          </DropdownItem>
        </ButtonGroup>
      </div>
      {searchObject.import ? (
        <div>
          <div onClick={() => updateSearchField("import", null)} className="cursor-pointer">
            <Title title={"<" + intl.formatMessage({id: "exports_tab_title"})} size={"h4"} className="mb-5"/>
          </div>
          {renderImportForm()}
        </div>
      ) : (
        <div className={`d-flex gap-2 flex-wrap ${className ?? ""}`}>
          <AdminExportCsvCard className="epow-card-admin-export"/>
          <AdminExportPdfCard className="epow-card-admin-export"/>
          <AdminExportServicesCsvCard className="epow-card-admin-export"/>
          <AdminExportBillingZipCard className="epow-card-admin-export"/>
          <AdminExportActivityCustomerCatalogCard className="epow-card-admin-export"/>
          <AdminExportCustomerServiceCatalogCard className="epow-card-admin-export"/>
          <AdminExportCatalogSupplierCsvCard className="epow-card-admin-export"/>
        </div>
      )}
    </>
  )
}

export default AdminExportsImports;
