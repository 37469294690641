import {SearchFilter} from "./api/FiltersInterface";
import {StateMap} from "../constants/workflow/StateConstants";
import {ColorType} from "../types/bootstrap/BootstrapType";
import {Address} from "./AddressInterfaces";
import {Comment} from "./CommentInterface";
import {CustomerGeographicalZone} from "../constants/CustomerConstants";

/**
 * @interface invoice
 * Contains all the information about an invoice sorted by Orders
 * then BillingItems (Activity or Service)
 * then BillingSubItem (Services form activity)
 *
 * Contains:
 * - InvoiceOrder
 * - InvoiceBillingItem
 * - InvoiceBillingSubItem
 *
 */
export interface Invoice {
  id: string,
  invoiceNumber: string,
  status: InvoiceState,
  customerOrderName: string,
  date: string,
  amountTTC: number,
  amountVAT: number,
  amountHT: number,
  totalAmountPaid?: number,
  totalAmountRemaining?: number,
  fullyPaidDate?: string,
  amountDisbursement: number,
  percentVAT: number,
  billingCustomer: InvoiceContact,
  comment: Comment,
  invoiceOrderDetails: InvoiceOrder[]
  additionnalCharges: InvoiceBillingItem[]
  linkedInvoiceId: string
  type: InvoiceType
  limitPaymentDate: string
  geographicalZone: CustomerGeographicalZone
  showToCustomer?: boolean
}

export interface InvoiceItemResponse {
  id: string,
  invoiceNumber: string,
  billingCustomerName: string,
  billingCustomerId: string,
  billingCustomerGeographicalZone: CustomerGeographicalZone,
  customerOrderName: string,
  customerOrderId: string,
  status: InvoiceState,
  type: InvoiceType,
  date: string,
  creationDate: string,
  amountTTC: number,
  amountVAT: number,
  vatRate: number,
  amountHT: number,
  amountDisbursement: number,
  firstOrderItemDate: string,
  lastOrderItemDate: string,
  hasPayment: boolean,
}

export interface AccountingInvoiceList {
  id?: string,
  geographicalZone: CustomerGeographicalZone,
  invoices: InvoiceItemResponse[],
  totalAmountHT: number,
}

export interface InvoiceOrder {
  orderId: string,
  orderNumber: string,
  refCustomer: string,
  orderDate: string,
  subTotalHT: number,
  billingItemDetails: InvoiceBillingItem[]
}


export interface InvoiceBillingItem extends BillingItem {
  amountTTC: number,
  fromCreditNote: boolean,
  creditNote: string,
  resourceConfirmedCount: number,
  showActivityTourLeader: boolean,
  billingSubItemDetails: InvoiceBillingSubItem[]
}

export interface BillingItem {
  orderId?: string
  orderItemId?: string,
  id?: string,
  name: string,
  unitPriceHT: number,
  quantity: number,
  amountHT: number,
  amountPaid?: number,
  amountRemaining?: number,
  disbursement: boolean,
  date: string
  status: "CANCELLED" | "VALIDATED"
}

interface InvoiceBillingSubItem extends BillingItem {
  amountTTC: number,
  fromCreditNote: boolean,
  creditNote: string
}


export interface InvoiceContact {
  customerId: string,
  customerName: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  hasAccessToPlatform: boolean,
  address: Address
}

export interface InvoiceFormData {
  date: string,
  billingCustomerId: string,
}

export interface InvoiceHT {
  totalHT: number,
}

export interface BillingFormData {
  orderId: string
  orderItemId: string,
  name: number,
  quantity: number,
  unitPriceHT: number
  disbursement: boolean
}

export interface InvoiceSearchObject extends SearchFilter {
  invoiceNumber?: string,
  status?: string[],
  startDate?: string,
  endDate?: string,
  customerIds?: string[],
  type?: InvoiceType,
  withDisbursement?: boolean,
  showDetails?: boolean,
}

export const InvoiceState = {
  DRAFT: "DRAFT",
  BILLED: "BILLED",
  LATE: "LATE",
  PAID: "PAID",
} as const

export const InvoiceType = {
  INVOICE: "INVOICE",
  CREDIT_NOTE: "CREDIT_NOTE"
} as const

export type InvoiceType = typeof InvoiceType[keyof typeof InvoiceType]
export type InvoiceState = typeof InvoiceState[keyof typeof InvoiceState]

export const InvoiceStateMap: StateMap = {
  [InvoiceState.DRAFT]: {
    id: InvoiceState.DRAFT,
    label: "invoice_state_draft",
    color: ColorType.SECONDARY,
  },
  [InvoiceState.BILLED]: {
    id: InvoiceState.BILLED,
    label: "invoice_state_billed",
    color: ColorType.WARNING,
  },
  [InvoiceState.LATE]: {
    id: InvoiceState.LATE,
    label: "invoice_state_late",
    color: ColorType.DANGER,
  },
  [InvoiceState.PAID]: {
    id: InvoiceState.PAID,
    label: "invoice_state_paid",
    color: ColorType.PRIMARY,
  }
}

export const CreditNoteStateMap: StateMap = {
  [InvoiceState.DRAFT]: {
    id: InvoiceState.DRAFT,
    label: "invoice_state_draft",
    color: ColorType.SECONDARY,
  },
  [InvoiceState.BILLED]: {
    id: InvoiceState.BILLED,
    label: "credit_note_state_billed",
    color: ColorType.PRIMARY,
  }
}
