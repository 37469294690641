import React, {FunctionComponent} from "react";
import {FileData} from "../../../../../interfaces/ResourceInterfaces";
import {ColorType} from "../../../../../types/bootstrap/BootstrapType";
import FilterGroup from "../../../filters/FilterGroup";
import {FormattedMessage, useIntl} from "react-intl";
import useSearchFilter from "../../../../../hooks/useSearchFilter";
import {fileUtils} from "../../../../../utils/fileUtils";
import Button from "../../../../atoms/Button";
import DateFilter from "../../../filters/DateFilter";
import {CatalogSupplierServiceSearchObject} from "../../../../../interfaces/CatalogSupplierServiceInterfaces";
import {supplierService} from "../../../../../services/SupplierService";
import AutocompleteMultiselect from "../../../filters/AutocompleteMultiselect";
import {supplierServiceService} from "../../../../../services/SupplierServiceService";

interface AdminExportCatalogSupplierCsvCardProps {
  className?: string,
}

const AdminExportCatalogSupplierCsvCard: FunctionComponent<AdminExportCatalogSupplierCsvCardProps> = ({
  className,
}) => {
  const intl = useIntl()
  const {searchObject, updateSearchField, onReset} = useSearchFilter<CatalogSupplierServiceSearchObject>({
    startDate: "",
    supplierIds: [],
  })
  const [loading, setLoading] = React.useState<boolean>(false)

  const downloadExport = (searchObject: CatalogSupplierServiceSearchObject) => {
    if (!searchObject.startDate) return
    setLoading(true)
    const fileData: FileData = {name: "export-supplier-catalog", extension: "csv"}
    fileUtils.downloadFile(
      supplierServiceService.exportSupplierServiceCatalogCsv(searchObject),
      fileData,
      intl,
      () => setLoading(false)
    )
  }

  return (
    <div className={`${className ?? ""}`}>
      <div className="fs-14"><FormattedMessage id="exports_supplier_service_catalog" /></div>
      <FilterGroup className="mb-4">
        <DateFilter
          required
          value={searchObject.startDate}
          onChangeDate={(value) => updateSearchField("startDate", value)}
          title={intl.formatMessage({id: "filter_from"})}
        />
        <AutocompleteMultiselect
          classNameLabel="epow-label"
          title={intl.formatMessage({id: "booking_filter_supplier"})}
          values={searchObject.supplierIds}
          onChange={(values) => updateSearchField("supplierIds", values)}
          fetchData={(page, filters) => supplierService.getSuppliersPaginated(page, {
            ...filters
          })}
          filterFieldName="name"
          filterFieldSearch="name"
        />

        <div>
          <Button onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <Button onClick={() => downloadExport(searchObject)} color={ColorType.SECONDARY} >
        {loading && <span className="loader loader-button-group ms-1"/>}
        {intl.formatMessage({id: "exports_supplier_service_catalog_button"})}
      </Button>
    </div>
  )
}

export default AdminExportCatalogSupplierCsvCard;
