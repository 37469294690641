import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {Col, Row} from "reactstrap";
import {ORDERS_PATH} from "../../../../constants/routes/RoutePaths";
import {OrderBasicDto} from "../../../../interfaces/OrderInterfaces";
import {OrderItemStaffingPageItemDto} from "../../../../interfaces/OrderItemInterfaces";
import TextLink from "../../../atoms/TextLink";
import StaffingTableStaffingSelectorButton from "./buttons/StaffingTableStaffingSelectorButton";
import {orderUtils} from "../../../../utils/orderUtils";
import ResourceCircle from "../../circle/ResourceCircle";
import {dateUtils} from "../../../../utils/dateUtils";

interface StaffingTableRowProps {
  className?: string,
  order: OrderBasicDto,
  orderItems: OrderItemStaffingPageItemDto[],
  selectedStaffing: string[],
  handleStaffingSelect: (orderId: string) => void,
  onStaffing: () => void,
}

const StaffingTableRow: FunctionComponent<StaffingTableRowProps> = ({
  className = "",
  order,
  orderItems,
  selectedStaffing,
  handleStaffingSelect,
  onStaffing
}) => {
  const intl = useIntl()

  const buildOrderTitle = () => {
    return `${intl.formatMessage({id: "staffing_order"})} : ${orderUtils.getOrderRefDisplay(order.orderNumber, order.customerReference, order.customerName)}`
  }

  return (
    <div className={`epow-staffing-table-row ${className ?? ""}`}>
      <div className="epow-staffing-table-row-title">
        <TextLink to={`${ORDERS_PATH}/${order.id}`}>{buildOrderTitle()}</TextLink>
      </div>

      {orderItems.map(orderItem => {
        const maxResources = orderItem.orderItemResources?.reduce((acc, orderItemResource) => {
            return acc + orderItemResource.quantity
          }, 0)

        return (
          <Row key={orderItem.id} className="epow-staffing-table-row-details">
            <Col className="d-flex">
              <input
                className="me-2"
                type="checkbox"
                checked={selectedStaffing.some(id => id === orderItem.id)}
                onChange={() => handleStaffingSelect(orderItem.id)}
              />
              {orderItem?.name}
            </Col>

            <Col>
              {`${dateUtils.formatDateDayJs(orderItem.rendezVousDate)} - ${dateUtils.formatLocalTime(orderItem.rendezVousHour)}`}
            </Col>

            <Col>
              {orderItem?.rendezVousPlace ?? "-"}
            </Col>

            <Col>
              <div className="d-flex">
                <span className="bold">{`${intl.formatMessage({id: "order_item_resources_to_staff"})} : `}</span>
                {
                  orderItem?.orderItemResources.map((orderItemResource) =>
                    <span key={orderItemResource.id} className="d-flex ms-2">
                      <ResourceCircle resourceType={orderItemResource.resourceType} className="me-1"/>
                      <span>{`x${orderItemResource.quantity}`}</span>
                    </span>
                  )
                }
              </div>
            </Col>

            <Col>
              <div className="float-end">
                <StaffingTableStaffingSelectorButton
                  onStaffing={onStaffing}
                  maxResources={maxResources}
                  orderItemId={orderItem.id}/>
              </div>
            </Col>
          </Row>
      )
      })}
    </div>
  )
}

export default StaffingTableRow;
