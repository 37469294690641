import {
  CustomerService,
  CustomerServiceCatalog,
  ReferenceResponse,
  Service,
  ServiceDefaultSupplierRequest,
  ServiceSearchObject
} from "../interfaces/ServiceInterfaces";
import {httpService} from "./HttpService";
import {
  API_CATALOG_CUSTOMER_SERVICES_PATH,
  API_CUSTOMER_SERVICES_PATH,
  API_REFERENCES_PATH,
  API_SERVICES_PATH
} from "../constants/routes/RoutePaths";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {Supplier} from "../interfaces/SupplierInterfaces";
import {queryUtils} from "../utils/queryUtils";
import {BookingPriceResponse} from "../interfaces/BookingInterfaces";

function getServices(): Promise<Service[]> {
  return httpService.get<Service[]>(API_SERVICES_PATH)
}

function getServicesActive(): Promise<Service[]> {
  return httpService.get<Service[]>(`${API_SERVICES_PATH}/active`)
}

function getBookingPriceForOrderItemAndSupplier(serviceId: string, orderItemid: string, supplierId: string): Promise<BookingPriceResponse> {
  return httpService.get<BookingPriceResponse>(`${API_SERVICES_PATH}/${serviceId}/price?orderItemId=${orderItemid}&supplierId=${supplierId}`)
}

function getServicesPage(params: PaginationQueryParams, filters: ServiceSearchObject): Promise<PageResponse<Service>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filters)
  return httpService.get<PageResponse<Service>>(`${API_SERVICES_PATH}/page${queryParams}`)
}

function getService(id: string, orderId?: string): Promise<Service> {
  return httpService.get<Service>(`${API_SERVICES_PATH}/${id}${orderId ? `?orderId=${orderId}` : ""}` )
}

function getServiceSuppliers(id: string): Promise<Service[]> {
  return httpService.get<Supplier[]>(`${API_SERVICES_PATH}/${id}/suppliers`)
}

function getCustomerServices(serviceId: string): Promise<CustomerServiceCatalog[]> {
  return httpService.get<CustomerServiceCatalog[]>(`${API_CUSTOMER_SERVICES_PATH}/${serviceId}`)
}

function getReference(serviceName: string): Promise<ReferenceResponse>{
  return httpService.get<ReferenceResponse>(`${API_REFERENCES_PATH}${API_SERVICES_PATH}/${serviceName}`)
}

function createService(serviceRequest: Service): Promise<Service> {
  return httpService.post<Service>(API_SERVICES_PATH, serviceRequest)
}

function createCustomerService(customerService: CustomerService): Promise<CustomerService> {
  return httpService.post<CustomerService>(API_CUSTOMER_SERVICES_PATH, customerService)
}

function createCustomerServiceCatalog(customerServiceCatalog: CustomerServiceCatalog): Promise<CustomerServiceCatalog> {
  return httpService.post<CustomerServiceCatalog>(API_CATALOG_CUSTOMER_SERVICES_PATH, customerServiceCatalog)
}

function updateService(id: string, service: Service): Promise<Service> {
  return httpService.put<Service>(`${API_SERVICES_PATH}/${id}`, service)
}

function updateServiceDefaultSupplier (id: string, request: ServiceDefaultSupplierRequest): Promise<Service> {
  return httpService.put<Service>(`${API_SERVICES_PATH}/${id}/defaultSupplier`, request)
}

function updateCustomerServiceCatalog (id: string, customerServiceCatalog: CustomerServiceCatalog): Promise<CustomerServiceCatalog> {
  return httpService.put<CustomerServiceCatalog>(`${API_CATALOG_CUSTOMER_SERVICES_PATH}/${id}`, customerServiceCatalog)
}

function deleteService(id: string): Promise<Response> {
  return httpService.delete(`${API_SERVICES_PATH}/${id}`)
}

function deleteCustomerServiceCatalog(customerServiceCatalogId: string): Promise<Response> {
  return httpService.delete(`${API_CATALOG_CUSTOMER_SERVICES_PATH}/${customerServiceCatalogId}`)
}

export const serviceService = {
  getServices,
  getServicesActive,
  getServiceSuppliers,
  getServicesPage,
  getService,
  getCustomerServices,
  getReference,
  createService,
  createCustomerService,
  createCustomerServiceCatalog,
  updateService,
  updateServiceDefaultSupplier,
  updateCustomerServiceCatalog,
  deleteService,
  deleteCustomerServiceCatalog,
  getBookingPriceForOrderItemAndSupplier
}
