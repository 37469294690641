import React, {FunctionComponent, useContext, useEffect, useMemo} from "react";
import TextInput from "../../atoms/input/TextInput";
import Button from "../../atoms/Button";
import {ColorType, IconSizeType, LogoSizeType} from "../../../types/bootstrap/BootstrapType";
import Icon from "../../icon/Icon";
import Dropdown from "../../atoms/input/Dropdown";
import {FormattedMessage, useIntl} from "react-intl";
import {DropdownOption} from "../../../interfaces/inputs/OptionInterfaces";
import {authUtils} from "../../../utils/authUtils";
import {PROFILE} from "../../../interfaces/UserInterfaces";
import {MenuCreateEntry} from "../../../constants/menu/Layout";
import Logo from "../../atoms/logo/Logo";
import {LOGO_POW} from "../../../constants/Assets";
import UserMenu from "./UserMenu";
import {ActiveCustomerContext, ActiveCustomerDispatchContext} from "../../../context/ActiveCustomerContext";
import { PictureType, PictureTypeProperties } from "constants/FileConstants";
import { fileService } from "services/FileService";
import {ActiveSupplierContext, ActiveSupplierDispatchContext} from "../../../context/ActiveSupplierContext";
import {CustomerIdentity} from "../../../interfaces/CustomerInterfaces";
import {SupplierIdentity} from "../../../interfaces/SupplierInterfaces";

interface HeaderMenuProps {
  className?: string,
  setActiveMenuEntry?: (entry?: MenuCreateEntry) => void,
  onToggleMenu: () => void
}

const HeaderMenu: FunctionComponent<HeaderMenuProps> = ({
  className = "",
  setActiveMenuEntry,
  onToggleMenu,
}) => {
  const intl = useIntl()
  const profile = authUtils.getProfile()
  const setActiveCustomer = useContext(ActiveCustomerDispatchContext);
  const setActiveSupplier = useContext(ActiveSupplierDispatchContext);
  const activeCustomer = useContext(ActiveCustomerContext);
  const activeSupplier = useContext(ActiveSupplierContext);
  const customersRepresented = authUtils.getCustomersRepresentedByUser();
  const supplierRepresented = authUtils.getSuppliersRepresentedByUser();
  const [image, setImage] = React.useState<string>();

  useEffect(() => {
    if (activeCustomer?.id !== undefined) {
      fileService.getPictureById(PictureType.CUSTOMER_LOGO, activeCustomer?.id).then((image) => {
        setImage(URL.createObjectURL(image))
      }).catch(() => {
        setImage(null)
      })
    }
  }, [activeCustomer?.id]);

  const createDropdownHeaderMenu: Array<DropdownOption> = useMemo(() => [
    {
      id: "dropdown_header_order",
      label: intl.formatMessage({id: "dropdown_header_order"}),
      roles: [
        PROFILE.ADMIN,
        PROFILE.INTERN,
      ],
      onClick: () => {
        setActiveMenuEntry(MenuCreateEntry.ORDER)
      }
    },
    {
      id: "dropdown_header_activity",
      label: intl.formatMessage({id: "dropdown_header_activity"}),
      roles: [
        PROFILE.ADMIN,
        PROFILE.INTERN,
      ],
      onClick: () => {
        setActiveMenuEntry(MenuCreateEntry.ACTIVITY)
      }
    },
    {
      id: "dropdown_header_tour",
      label: intl.formatMessage({id: "dropdown_header_tour"}),
      roles: [
        PROFILE.ADMIN,
        PROFILE.INTERN,
      ],
      onClick: () => {
        setActiveMenuEntry(MenuCreateEntry.TOUR)
      }
    },
    {
      id: "dropdown_header_service",
      label: intl.formatMessage({id: "dropdown_header_service"}),
      roles: [
        PROFILE.ADMIN,
        PROFILE.INTERN,
      ],
      onClick: () => {
        setActiveMenuEntry(MenuCreateEntry.SERVICE)
      }
    },
    {
      id: "dropdown_header_resource",
      label: intl.formatMessage({id: "dropdown_header_resource"}),
      roles: [
        PROFILE.ADMIN,
        PROFILE.INTERN,
      ],
      onClick: () => {
        setActiveMenuEntry(MenuCreateEntry.RESOURCE)
      }
    },
    {
      id: "dropdown_header_supplier",
      label: intl.formatMessage({id: "dropdown_header_supplier"}),
      roles: [
        PROFILE.ADMIN,
        PROFILE.INTERN,
      ],
      onClick: () => {
        setActiveMenuEntry(MenuCreateEntry.SUPPLIER)
      }
    },
    {
      id: "dropdown_header_tour_leader",
      label: intl.formatMessage({id: "dropdown_header_tour_leader"}),
      roles: [
        PROFILE.ADMIN,
        PROFILE.INTERN,
      ],
      onClick: () => {
        setActiveMenuEntry(MenuCreateEntry.TOUR_LEADER)
      }
    },
    {
      id: "dropdown_header_customer",
      label: intl.formatMessage({id: "dropdown_header_customer"}),
      roles: [
        PROFILE.ADMIN,
        PROFILE.INTERN,
      ],
      onClick: () => {
        setActiveMenuEntry(MenuCreateEntry.CLIENT)
      }
    },
    {
      id: "dropdown_header_invoice",
      label: intl.formatMessage({id: "dropdown_header_invoice"}),
      roles: [
        PROFILE.ADMIN,
        PROFILE.INTERN,
      ],
      onClick: () => {
        setActiveMenuEntry(MenuCreateEntry.INVOICE)
      }
    },
    {
      id: "dropdown_header_user",
      label: intl.formatMessage({id: "dropdown_header_user"}),
      roles: [
        PROFILE.ADMIN,
      ],
      onClick: () => {
        setActiveMenuEntry(MenuCreateEntry.USER)
      }
    },
    {
      id: "dropdown_header_quote",
      label: intl.formatMessage({id: "dropdown_header_quote"}),
      roles: [
        PROFILE.ADMIN,
        PROFILE.INTERN,
      ],
      onClick: () => {
        setActiveMenuEntry(MenuCreateEntry.QUOTE)
      }
    },
  ], [setActiveMenuEntry]);


  const createDropdownForCustomerChoice: Array<DropdownOption> = useMemo(() => {
    return customersRepresented.map(customer => ({
      id: customer.id,
      label: customer.name,
      onClick: () => {
        setActiveCustomer(customer)
      }
    }));
  }, [setActiveCustomer])

  const createDropdownForSupplierChoice: Array<DropdownOption> = useMemo(() => {
    return supplierRepresented.map(supplier => ({
      id: supplier.id,
      label: supplier.name,
      onClick: () => {
        setActiveSupplier(supplier)
      }
    }));
  }, [setActiveCustomer])

  const filterOptionsByProfile = (options: Array<DropdownOption>): Array<DropdownOption> => {
    return options.filter((option: DropdownOption) => {
      const roles: string[] = option?.roles
      return roles ? roles.some((role: string) => role === profile) : option
    })
  }

  const customerLogo = () => {
    return (
      <div className={"wrap ms-auto"}>
        {image && (
          <img
            className="p-1 bg-theme-light"
            src={image}
            width={PictureTypeProperties[PictureType.HEADER_CUSTOMER_LOGO].pictureWidth}
            height={PictureTypeProperties[PictureType.HEADER_CUSTOMER_LOGO].pictureHeight}
            alt="image"
            loading="lazy"
          />
        )}
        <span className="text-uppercase customer-label ms-2">{activeCustomer?.name}</span>
        {customersRepresented && (
          <Icon className="ms-2" size={IconSizeType.XS} name="ChevronDown" />
        )}
      </div>
    )
  }

  const supplierText = () => {
    return (
      <div className={"wrap ms-auto"}>
        <span className="text-uppercase customer-label ms-2">{activeSupplier?.name}</span>
        {supplierRepresented && (
          <Icon className="ms-2" size={IconSizeType.XS} name="ChevronDown" />
        )}
      </div>
    )
  }

  const renderDropdown = (profileType: string, represented: CustomerIdentity[] | SupplierIdentity[], dropdownOptions: Array<DropdownOption>, logoFunction: Function, messageId: string) => {
    if (authUtils.getProfile() === profileType && represented) {
      return represented.length > 1 ? (
        <Dropdown isToggle={false} id="customer_choice" className="ms-auto" options={dropdownOptions} >
          <div className="customer_dropdown d-flex flex-column flex-md-row align-items-center text-uppercase">
          <span className="fw-normal text-capitalize me-2">
            <FormattedMessage id={messageId}  />
          </span>
            <div>
              {logoFunction()}
            </div>
          </div>
        </Dropdown>
      ) : logoFunction();
    }
    return null;
  }

  return (
    <div className={`epow-header-menu d-flex flex-row align-items-center justify-content-between px-lg-5 ${className}`}>
      <div className="d-flex flex-row d-lg-none">
        <Icon className="cursor-pointer ms-2" size={IconSizeType.MD} onClick={onToggleMenu} color="light" name="Menu" />
        <Logo className="epow-logo logo ms-4" imageSource={LOGO_POW} height={LogoSizeType.MD} isExternal={true} alt="Paris On The Way" />
      </div>
      <div className="d-flex flex-row align-items-center justify-content-end justify-content-lg-between w-100">
        {(authUtils.getProfile() === PROFILE.ADMIN || authUtils.getProfile() === PROFILE.INTERN) && false && (
            <div className="d-none d-lg-flex flex-row align-items-center w-50">
              <TextInput
                type="search"
                iconSize={IconSizeType.XXS}
                icon="Search"
                placeholder={intl.formatMessage({id: "header_menu_placeholder"})} />
              <Button className="btn-black btn-transparent">
                {intl.formatMessage({id: "header_menu_search_button"})}
              </Button>
            </div>
        )}
        {renderDropdown(PROFILE.CLIENT, customersRepresented, createDropdownForCustomerChoice, customerLogo, "dashboard_customer_select_customer")}
        {renderDropdown(PROFILE.SUPPLIER, supplierRepresented, createDropdownForSupplierChoice, supplierText, "dashboard_supplier_select_supplier")}

        <div className="d-flex flex-row align-items-center ms-auto">
          <Dropdown isToggle={false} id="header_menu_create" options={filterOptionsByProfile(createDropdownHeaderMenu)} color={ColorType.PRIMARY}>
            <div className="d-flex flex-row align-items-center">
              {intl.formatMessage({id: "header_menu_create_button"})}
              <Icon className="ms-2" size={IconSizeType.XS} name="ChevronDown" />
            </div>
          </Dropdown>
          <div className="separator d-none d-lg-block"></div>
          <UserMenu className="d-none d-lg-block" />
        </div>
      </div>
    </div>
  )
}

export default HeaderMenu
