import React, {FunctionComponent, useState} from "react";
import {RouteProps} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {catalogActivityCustomerService} from "../../../../../services/CatalogActivityCustomerService";
import {fileUtils} from "../../../../../utils/fileUtils";
import {Button, Input, Label} from "reactstrap";
import {toastUtils} from "../../../../../utils/toastUtils";
import {CatalogActivityCustomerCsv} from "../../../../../interfaces/CatalogActivityCustomerInterfaces";
import {dateUtils} from "../../../../../utils/dateUtils";
import {templateService} from "../../../../../services/TemplateService";
import {ImportTemplateType} from "../../../../../interfaces/TemplateInterfaces";
import {CatalogImportError} from "../../../../../interfaces/ImportInterface";
import {getClassNameByErrorType} from "../../../../../utils/importUtils";

const AdminImportActivityCustomerCatalog: FunctionComponent<RouteProps> = () => {
  const [csvCatalogActivityCustomer, setCatalogActivityCustomer] = useState<CatalogActivityCustomerCsv[]>(null)
  const [errorMessages, setErrorMessages] = useState<string[]>([])

  const intl = useIntl()

  const sendCsv = (file: File) => {
    if (file) {
      catalogActivityCustomerService.sendCsv(file).then(response => {
        setCatalogActivityCustomer(response)
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "imports_csv_error"}))
      })
    }
  }

  const handleCatalogsCsvImport = (e: React.FormEvent<HTMLInputElement>) => {
    const selectedFile = (e.target as HTMLInputElement).files[0];
    sendCsv(selectedFile)
  };

  const sendCatalog = () => {
    catalogActivityCustomerService.importCatalogActivityCustomer(csvCatalogActivityCustomer)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "imports_csv_success"}))
      })
      .catch((e) => {
        const messages = e.message.split("\n")
        setErrorMessages(messages)
        toastUtils.errorToast(intl.formatMessage({id: "imports_csv_error"}))
      })
  }

  return (
    <div className="d-flex flex-column mt-5">
      <h2 className="ms-2 bold epow-label">{intl.formatMessage({id: "imports_activity_customer_catalog_title"})}</h2>

      <div className="d-flex align-content-center align-self-center col-12 my-4">
        <Input type="file" accept=".csv" onChange={handleCatalogsCsvImport}/>
      </div>

      <div className="epow-text-link fs-6 cursor-pointer " onClick={() => {
        const templateCsv = templateService.getTemplateCsv(ImportTemplateType.TEMPLATE_ACTIVITY_CUSTOMER_CATALOG);
        fileUtils.downloadFile(templateCsv, {extension: "csv", name: "template_import_activity_customer_catalog"}, intl)
      }}>
        <FormattedMessage id="imports_csv_template"/>
      </div>

      {csvCatalogActivityCustomer && csvCatalogActivityCustomer.length > 0 && <div className="d-flex flex-column  w-100">

        <div className="d-flex w-100">
          <div className="col-1"></div>
          <Label className="col-2 text-center bold"><FormattedMessage id="imports_activity_customer_catalog_customer_name" /></Label>
          <Label className="col-2 text-center bold"><FormattedMessage id="imports_activity_customer_catalog_customer_date_validity" /></Label>
          <Label className="col-2 text-center bold"><FormattedMessage id="imports_activity_customer_catalog_activity_name" /></Label>
          <Label className="col-2 text-center bold"><FormattedMessage id="imports_activity_customer_catalog_customer_unity" /></Label>
          <Label className="col-1 text-center bold"><FormattedMessage id="imports_activity_customer_catalog_customer_pax" /></Label>
          <Label className="col-3 text-center bold"><FormattedMessage id="imports_activity_customer_catalog_customer_sellprice" /></Label>
          <Label className="col-11 text-center bold"><FormattedMessage id="imports_activity_customer_catalog_customer_description" /></Label>
        </div>

        {csvCatalogActivityCustomer?.map((item, index) =>
          <div key={index} className="d-flex mb-3">
            <div className="col-1 bold">{index}</div>
            <Label className={`col-2 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.UNKNOWN_CUSTOMER)}`}>{item.customerName}</Label>
            <Label className={`col-2 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.INVALID_DATE)}`}>{dateUtils.formatDateDayJs(item.validityDate)}</Label>
            <Label className={`col-2 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.UNKNOWN_ACTIVITY)}`}>{item.activityName}</Label>
            <Label className={`col-2 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.INVALID_UNIT)}`}>{item.unitType}</Label>
            <Label className={`col-1 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.INVALID_PAX)}`}>{item.pax}</Label>
            <Label className={`col-3 text-center ${getClassNameByErrorType(errorMessages[index], CatalogImportError.INVALID_PRICE)}`}>{item.sellingPrice}</Label>
            <Label className="col-11">{item.description}</Label>
          </div>
        )}

        <div className="d-flex w-100 justify-content-end align-content-center p-3">
          <Button onClick={sendCatalog}>{intl.formatMessage({id: "imports_activity_customer_catalog_customer_create_button"})}</Button>
        </div>
      </div>}
    </div>
  )
}

export default AdminImportActivityCustomerCatalog
