import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {RouteProps} from "react-router-dom";
import Layout from "../components/layout/Layout";
import PageTitle from "../components/molecules/header/PageTitle";
import {Col, Row} from "reactstrap";
import PurchaseOrdersFormList from "../components/molecules/list/PurchaseOrdersFormList";

const PurchaseOrdersFormListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()

  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column epow-order-form">
        <Row className="mb-5">
          <Col xs={12}>
            <PageTitle pageTitle={intl.formatMessage({id: "purchase_update_orders"})}></PageTitle>
          </Col>
        </Row>
        <PurchaseOrdersFormList/>
      </div>
    </Layout>
  )
}

export default PurchaseOrdersFormListView;
