import React, {FunctionComponent, useMemo, useRef} from "react";
import CustomTable, {CustomTableRef} from "../CustomTable";
import {SortOptions} from "../../../../interfaces/api/PaginationInterface";
import {InvoiceItemResponse} from "../../../../interfaces/InvoiceInterfaces";
import BillingTableRow from "./BillingTableRow";
import {CustomTableType} from "../../../../interfaces/TableInterfaces";
import {useIntl} from "react-intl";

interface InvoiceTableProps {
  className?: string,
  invoices: InvoiceItemResponse[],
  sortOptions: SortOptions,
  onSortOptions: (sortOptions: SortOptions) => void
  isCustomer?: boolean
}

const BillingTable: FunctionComponent<InvoiceTableProps> = ({
  className = "",
  invoices,
  sortOptions,
  onSortOptions,
  isCustomer = false
}) => {
  const  ref = useRef<CustomTableRef>();

  const intl = useIntl();

  const columns = useMemo(() => [
    { title: "invoice_number", accessor: "invoiceNumber",sortable: true, minWidth: "12.5%", className:"col-3" },
    { title: "invoice_customer_name", accessor: "billingCustomerName", sortable: !isCustomer, className:"col-3", minWidth: "12.5%" },
    { title: "invoice_name_order", sortable: false, className:"col-3", minWidth: "12.5%" },
    { title: "invoice_period", sortable: false, className:"col-3", minWidth: "12.4%"},
    { title: "invoice_created_date", accessor: "creationDate", sortable: true, className:"col-3", minWidth: "12.5%" },
    { title: "invoice_price_ht", accessor:"amountHT", sortable: false, className:"col-2" },
    { title: "invoice_price_ttc", accessor:"amountTTC", sortable: true, className:"col-2 me-4" },
    { title: "invoice_statut",  accessor: "status", sortable: true, className:"col-3 me-5" },
    { title: "table_actions", sortable: false, className:"col-2" },
  ], [intl]);

  return (
    <CustomTable
      ref={ref}
      onSortOptions={onSortOptions}
      sortOptions={sortOptions}
      className={className}
      type={CustomTableType.DETAILS_TABLE}
      datas={invoices}
      columns={columns}
      RowComponent={({data}) => <BillingTableRow invoice={data as InvoiceItemResponse}/>}
      />);
}

export default BillingTable;
