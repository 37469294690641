import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import useSearchFilter from "../../../../../hooks/useSearchFilter";
import {ColorType} from "../../../../../types/bootstrap/BootstrapType";
import FilterGroup from "../../../filters/FilterGroup";
import Button from "../../../../atoms/Button";
import DateFilter from "../../../filters/DateFilter";
import {CatalogActivityCustomerSearchObject} from "../../../../../interfaces/CatalogActivityCustomerInterfaces";
import {customerService} from "../../../../../services/CustomerService";
import AutocompleteFilterPageable from "../../../filters/AutocompleteFilterPageable";
import {catalogActivityCustomerService} from "../../../../../services/CatalogActivityCustomerService";
import {fileUtils} from "../../../../../utils/fileUtils";

interface AdminExportActivityCustomerCatalogCard {
  className?: string,
}

const AdminExportActivityCustomerCatalogCard: FunctionComponent<AdminExportActivityCustomerCatalogCard> = ({
  className,
}) => {
  const intl = useIntl()
  const {searchObject, updateSearchField, onReset} = useSearchFilter<CatalogActivityCustomerSearchObject>({
    startDate: "",
    customerId: "",
  })
  const [loading, setLoading] = React.useState<boolean>(false)

  const downloadExportPdf = (searchObject) => {
    setLoading(true)
    const fileData = {name: "export-catalog-activity-customer", extension: "csv"}
    fileUtils.downloadFile(
      catalogActivityCustomerService.exportCatalogActivityCustomerCsv(searchObject),
      fileData,
      intl,
      () => setLoading(false)
    )
  }

  return (
    <div className={`${className ?? ""}`}>

      <div className="fs-14"><FormattedMessage id="exports_activity_customer_catalog" /></div>

      <FilterGroup className="mb-4">
        <DateFilter
          required
          value={searchObject.startDate}
          onChangeDate={(value) => updateSearchField("startDate", value)}
          title={intl.formatMessage({id: "filter_starting_from"})}
        />

        <AutocompleteFilterPageable
          onChange={(value) => updateSearchField("customerId", value)}
          className="w-100"
          title={intl.formatMessage({id: "order_filter_customer"})}
          value={searchObject?.customerId}
          fetchData={customerService.getCustomersPage}
          filterFieldName="name"
        />

        <div>
          <Button onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <Button onClick={() => downloadExportPdf(searchObject)} color={ColorType.SECONDARY}>
        {loading && <span className="loader loader-button-group ms-1"/>}
        {intl.formatMessage({id: "exports_activity_customer_catalog_button"})}
      </Button>
    </div>
  )
}

export default AdminExportActivityCustomerCatalogCard;
