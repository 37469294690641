import React, {FunctionComponent, useMemo, useRef, useState} from "react";
import CustomTable, {CustomTableRef} from "../CustomTable";
import {SortOptions} from "../../../../interfaces/api/PaginationInterface";
import {AccountingInvoiceList, InvoiceItemResponse} from "../../../../interfaces/InvoiceInterfaces";
import {CustomTableType} from "../../../../interfaces/TableInterfaces";
import {useIntl} from "react-intl";
import {CustomerGeographicalZone} from "../../../../constants/CustomerConstants";
import BillingByCustomerZoneTableRow from "./BillingByCustomerZoneTableRow";

interface BillingByCustomerZoneTableProps {
  className?: string,
  invoices: InvoiceItemResponse[],
  sortOptions: SortOptions,
  onSortOptions: (sortOptions: SortOptions) => void
}

const BillingByCustomerZoneTable: FunctionComponent<BillingByCustomerZoneTableProps> = ({
  className = "",
  invoices,
  sortOptions,
  onSortOptions
}) => {
  const ref = useRef<CustomTableRef>();
  const [openRow, setOpenRow] = useState<{[index: string]: boolean}>({});

  const intl = useIntl();

  const invoicesByCustomerZone: AccountingInvoiceList[] = Object.values(invoices.reduce((acc, invoice) => {
    const customerZone = invoice.billingCustomerGeographicalZone;
    if (!acc[customerZone]) {
      acc[customerZone] = {
        id: customerZone,
        geographicalZone: customerZone,
        invoices: [],
        totalAmountHT: 0,
      };
    }
    acc[customerZone].invoices.push(invoice);
    acc[customerZone].totalAmountHT += invoice.amountHT;
    return acc;
  }, {} as Record<CustomerGeographicalZone, AccountingInvoiceList>));

  const columns = useMemo(() => [
    { title: "invoice_number", accessor: "invoiceNumber",sortable: true, minWidth: "12.5%", className:"col-3" },
    { title: "invoice_customer_name", accessor: "billingCustomerName", sortable: true, className:"col-3", minWidth: "12.5%" },
    { title: "invoice_name_order", sortable: false, className:"col-3", minWidth: "12.5%" },
    { title: "invoice_period", sortable: false, className:"col-3", minWidth: "12.4%"},
    { title: "invoice_created_date", accessor: "creationDate", sortable: true, className:"col-3", minWidth: "12.5%" },
    { title: "invoice_price_ht", accessor:"amountHT", sortable: false, className:"col-2" },
    { title: "invoice_price_ttc", accessor:"amountTTC", sortable: true, className:"col-2 me-4" },
    { title: "invoice_statut",  accessor: "status", sortable: true, className:"col-3 me-5" },
    { title: "table_actions", sortable: false, className:"col-2" },
  ], [intl]);

  return (
    <CustomTable
      ref={ref}
      onSortOptions={onSortOptions}
      sortOptions={sortOptions}
      className={className}
      type={CustomTableType.DETAILS_TABLE}
      datas={invoicesByCustomerZone}
      columns={columns}
      RowComponent={({data}) => <BillingByCustomerZoneTableRow invoicesByCustomerZone={data as AccountingInvoiceList} openRow={openRow} setOpenRow={setOpenRow} />}
      />);
}

export default BillingByCustomerZoneTable;
