import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import GenericModal from "./GenericModal";
import {BookingTableDto} from "../../../interfaces/BookingInterfaces";
import {moneyUtils} from "../../../utils/moneyUtils";

interface ModalModifyBookingsProps {
  open: boolean,
  onCancel: () => void,
  onValidate: () => void,
  datas: BookingTableDto[]
}

const ModalModifyBookings: FunctionComponent<ModalModifyBookingsProps> = ({
  open,
  onValidate,
  onCancel,
  datas,
}) => {
  const intl = useIntl()
  const validateButton = intl.formatMessage({id: "validate_button"})
  const cancelButton = intl.formatMessage({id: "cancel_button"})

  return (
    <GenericModal
      open={open}
      className="p-3"
      title={intl.formatMessage({id:"booking_modify_modal_title"})}
      footer={{
        primaryAction: {
          buttonLabel: validateButton,
          action: onValidate
        },
        secondaryAction: {
          buttonLabel: cancelButton,
          action: onCancel
        }
      }}
    >
      <div>
        <p><FormattedMessage id="booking_modify_modal_info"/></p>
        <ul>
          {datas.map((data, index) => {
           return <li key={index}>
             {data.serviceName} | {data.orderRef} -
             {" "}<span className="fw-bold">{moneyUtils.formatNumberToCurrency(data.catalogService.unitPrice)}</span>
             {" ("}<span className="text-decoration-line-through">{moneyUtils.formatNumberToCurrency(data.booking.price)}</span>)
           </li>
          })}
        </ul>
      </div>
    </GenericModal>
  )
}

export default ModalModifyBookings
