import {SortOptions} from "../../../../interfaces/api/PaginationInterface";
import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import CustomTable from "../CustomTable";
import {Input} from "reactstrap";
import ButtonRowSelectAction from "../ButtonRowSelectAction";
import {CustomTableType} from "../../../../interfaces/TableInterfaces";
import {ActivityWithUsage, ActivityWithUsageSelecteble} from "../../../../interfaces/ActivityInterfaces";
import OrderItemsFormTableRow from "./OrderItemsFormTableRow";
import ModalModifyOrderItems from "../../modal/ModalModifyOrderItems";
import {OrderItemDefaultSelectable} from "../../../../interfaces/OrderItemInterfaces";

interface OrderItemsFormTableProps {
  className?: string,
  activities: ActivityWithUsage[],
  sortOptions: SortOptions,
  onSortOptions: (sortOptions: SortOptions) => void,
  refresh?: () => void,
}

const OrderItemsFormTable: FunctionComponent<OrderItemsFormTableProps> = ({
  className,
  activities,
  sortOptions,
  onSortOptions,
  refresh = () => null,
}) => {
  const intl = useIntl();
  const [rowOpen, setRowOpen] = useState<{[index: string]: boolean}>({});
  const [activitiesSelectable, setActivitiesSelectable] = useState<ActivityWithUsageSelecteble[]>([]);
  const allOrderItemsSelected = activitiesSelectable.map(activity => activity.orderItems.map(orderItem => orderItem.isSelected)).flat().every(isSelected => isSelected)
  const [atLeastOneSelected, setAtLeastOneSelected] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrderItems, setSelectedOrderItems] = useState<OrderItemDefaultSelectable[]>([]);

  useEffect(() => {
    setActivitiesSelectable(activities.map(activity => {
      return {
        ...activity,
        orderItems: activity.orderItems.map(orderItem => ({...orderItem, isSelected: false})),
        isSelected: false
      }
    }))
  }, [activities])

  const handleSelectAll = (allSelected: boolean) => {
    setActivitiesSelectable(activitiesSelectable.map(activity => {
      return ({
        ...activity,
        orderItems: activity.orderItems.map(orderItem => ({...orderItem, isSelected: !allSelected})),
        isSelected: !allSelected,
      });
    }))
  }

  const handleFormValueChange = (activityUpdated: ActivityWithUsageSelecteble) => {
    const index = activitiesSelectable.findIndex(selectedBooking => selectedBooking.id === activityUpdated.id)
    if (index !== -1) {
      const updated = [...activitiesSelectable]
      updated.splice(index, 1)
      setActivitiesSelectable([...updated, activityUpdated])
    }
  }

  const handleValidateSelection = () => {
    setModalOpen(true);
    setSelectedOrderItems(activitiesSelectable.map(activity => activity.orderItems.filter(orderItem => orderItem.isSelected)).flat());
  }

  const handleValidateModale = () => {
    setModalOpen(false);
    setSelectedOrderItems([]);
    refresh();
  }

  const headerProps = {
    sortOptions,
    onSortOptions,
    className: "epow-custom-table-header",
  }

  useEffect(() => {
    const isAnySelected = activitiesSelectable.some(activity =>
      activity.orderItems.some(orderItem => orderItem.isSelected)
    );
    setAtLeastOneSelected(isAnySelected);
  }, [activitiesSelectable]);

  const columns = useMemo(() =>
       [
          {
            accessor: "name", title: "billing_table_header_name", ...headerProps,
            Cell: (<>
              <Input
                className="me-2"
                type="checkbox"
                checked={allOrderItemsSelected}
                onChange={() => handleSelectAll(allOrderItemsSelected)}
              />
              <span>{intl.formatMessage({id: "billing_table_header_name"})}</span>
            </>),
            minWidth: "17.5%",
          },
          { accessor: "id", title: "order_title", ...headerProps, minWidth: "15%" },
          { accessor: "catalogDescription", title: "order_item_description", ...headerProps, minWidth: "50%",},
          { accessor: "catalogPaxPrice", title: "order_item_pax_price", ...headerProps, minWidth: "15%" }
       ]
    , [allOrderItemsSelected, activities, activitiesSelectable])

  return (
    <div className={`epow-custom-table booking ${className ?? ""}`}>
      <CustomTable
        onSortOptions={onSortOptions}
        sortOptions={sortOptions}
        datas={activitiesSelectable}
        type={CustomTableType.BOOKING}
        columns={columns}
        RowComponent={({data, index}) => {
          const activity = data as ActivityWithUsageSelecteble;
          return <OrderItemsFormTableRow
            key={data.id}
            handleValueChange={handleFormValueChange}
            activitiesSelectable={activitiesSelectable}
            setActivitiesSelectable={setActivitiesSelectable}
            isSelected={activitiesSelectable.some(activitySelectable => activitySelectable.isSelected && activitySelectable.id === data.id)}
            activity={activity}
            index={index}
            rowOpen={rowOpen}
            setRowOpen={setRowOpen}
          />;
        }}
      />

      {atLeastOneSelected &&
        <div className="d-flex justify-content-end">
          <ButtonRowSelectAction
            onClick={handleValidateSelection}
            numberOfElements={activitiesSelectable.map(activity => activity.orderItems.map(orderItem => orderItem.isSelected)).flat().filter(isSelected => isSelected).length}
            label="order_items_mass_update"
          />
        </div>
      }
    <ModalModifyOrderItems open={modalOpen} onCancel={() => setModalOpen(false)} onValidate={handleValidateModale} datas={selectedOrderItems} />
    </div>
  )
}

export default OrderItemsFormTable
