import React, {FunctionComponent, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {RouteProps} from "react-router-dom";
import useSearchFilter from "../../../hooks/useSearchFilter";
import FilterGroup from "../filters/FilterGroup";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import {tourService} from "../../../services/TourService";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import usePaginatedData from "../../../hooks/usePaginatedData";
import OrderItemsFormTable from "../table/orderItem/OrderItemsFormTable";
import Pagination from "../pagination/Pagination";
import {activityService} from "../../../services/ActivityService";
import DateFilter from "../filters/DateFilter";
import {customerService} from "../../../services/CustomerService";
import {OrderItemSearchObject} from "../../../interfaces/OrderItemInterfaces";
import {OrderState} from "../../../interfaces/OrderInterfaces";

const OrderItemsFormList: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()

  const initialSearchState: OrderItemSearchObject = useMemo(() => ({
    orderTourId: null,
    customerId: null,
    startDate: null,
    endDate: null,
    orderStatuses: [OrderState.DRAFT, OrderState.PENDING]
  }), [])

  const {searchObject, updateSearchField} = useSearchFilter<OrderItemSearchObject>(initialSearchState)

  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total,
    refresh
  } = usePaginatedData(activityService.getActivitiesUsagePage, searchObject)

  return (
    <div>
      <div>
        <FilterGroup className="d-flex flex-wrap">
          <FormAutocompleteSelectPageable
            id="customer"
            className="ms-2 w-25 epow-filter"
            label={intl.formatMessage({id: "order_customer"})}
            placeholder={intl.formatMessage({id: "customer_placeholder"})}
            onChange={(value) => updateSearchField("customerId", [value])}
            value={searchObject?.customerId}
            fetchData={(page, filter) => customerService.getCustomersPage(page, {
              ...filter,
              currentState: WorkflowStatesEnum.ACTIVE
            })}
            filterFieldName="fullName"
            filterFieldSearch="name"
            error={null}
            touched={null}
          />
          <FormAutocompleteSelectPageable
            id="tour"
            className="ms-2 w-25 epow-filter"
            label={intl.formatMessage({id: "order_tour"})}
            placeholder={intl.formatMessage({id: "tour_placeholder"})}
            onChange={(value) => updateSearchField("orderTourId", value)}
            value={searchObject.orderTourId}
            fetchData={(page, filter) => tourService.getToursPage(page, {
              ...filter,
              currentState: WorkflowStatesEnum.ACTIVE
            })}
            filterFieldName="fullName"
            filterFieldSearch="tourName"
            error={null}
            touched={null}
          />
          <DateFilter className="mb-3 ms-2" value={searchObject.startDate} onChangeDate={(value) => updateSearchField("startDate", value)} title={intl.formatMessage({id: "filter_from"})} />
          <DateFilter className="mb-3 ms-2" value={searchObject.endDate} onChangeDate={(value) => updateSearchField("endDate", value)} title={intl.formatMessage({id: "filter_to"})} />
          <div className="mb-3">
            <Button
              className="mx-2"
              onClick={() => {
                updateSearchField("customerId", null)
                updateSearchField("orderTourId", null)
              }}
              color={ColorType.SECONDARY}>
                <FormattedMessage id="header_menu_clear_search"/>
            </Button>
          </div>
        </FilterGroup>
      </div>
      <div>
        <OrderItemsFormTable activities={data} sortOptions={sortOptions} onSortOptions={setSortOptions} refresh={refresh} />
        <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total}/>
      </div>
    </div>
  )
}

export default OrderItemsFormList;
