import {SupplierService, SupplierServiceCatalog} from "../interfaces/SupplierInterfaces";
import {httpService} from "./HttpService";
import {API_CATALOG_SUPPLIER_SERVICE_PATH, API_SUPPLIER_SERVICE_PATH} from "../constants/routes/RoutePaths";
import {
  CatalogSupplierServiceCsv,
  CatalogSupplierServiceSearchObject
} from "../interfaces/CatalogSupplierServiceInterfaces";
import {queryUtils} from "../utils/queryUtils";

function getSuppliersByService(id: string): Promise<SupplierService[]> {
  return httpService.get<SupplierService[]>(`${API_SUPPLIER_SERVICE_PATH}/${id}`);
}

function createSupplierServiceCatalog(supplierServiceCatalog: SupplierServiceCatalog): Promise<SupplierServiceCatalog> {
  return httpService.post<SupplierServiceCatalog>(API_CATALOG_SUPPLIER_SERVICE_PATH, supplierServiceCatalog)
}

function updateSupplierServiceCatalog (id: string, supplierServiceCatalog: SupplierServiceCatalog): Promise<SupplierServiceCatalog> {
  return httpService.put<SupplierServiceCatalog>(`${API_CATALOG_SUPPLIER_SERVICE_PATH}/${id}`, supplierServiceCatalog)
}

function deleteSupplierServiceCatalog(supplierServiceCatalogId: string): Promise<Response> {
  return httpService.delete(`${API_CATALOG_SUPPLIER_SERVICE_PATH}/${supplierServiceCatalogId}`)
}

function exportSupplierServiceCatalogCsv(searchObject: CatalogSupplierServiceSearchObject): Promise<Blob> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(null, searchObject)
  return httpService.getReadableStream(`${API_CATALOG_SUPPLIER_SERVICE_PATH}/export${queryParams}`);
}

function getCatalogTemplateCsv(): Promise<Blob> {
  return httpService.getReadableStream(`${API_CATALOG_SUPPLIER_SERVICE_PATH}/import-template`);
}

function sendCsv(file: File): Promise<CatalogSupplierServiceCsv[]> {
  const formData = new FormData()
  formData.append("file", file)
  return httpService.postFormData<CatalogSupplierServiceCsv[]>(`${API_CATALOG_SUPPLIER_SERVICE_PATH}/csv-import`, formData)
}

function importCatalogSupplierService(catalogSupplierServiceCsv: CatalogSupplierServiceCsv[]): Promise<CatalogSupplierServiceCsv[]> {
  return httpService.post<CatalogSupplierServiceCsv[]>(`${API_CATALOG_SUPPLIER_SERVICE_PATH}/import`, catalogSupplierServiceCsv)
}

export const supplierServiceService = {
  getSuppliersByService,
  createSupplierServiceCatalog,
  updateSupplierServiceCatalog,
  deleteSupplierServiceCatalog,
  exportSupplierServiceCatalogCsv,
  getCatalogTemplateCsv,
  sendCsv,
  importCatalogSupplierService
}
