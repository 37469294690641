import React, {FunctionComponent} from "react";
import ButtonGroup from "../../button/ButtonGroup";
import {DropdownItem} from "reactstrap";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import {PURCHASE_ORDERS_FORM_PATH} from "../../../../constants/routes/RoutePaths";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";

interface PurchaseListActionsDropdownProps {
  className?: string,
}

const PurchaseListActionsDropdown: FunctionComponent<PurchaseListActionsDropdownProps> = ({
  className = "",
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  return (
    <ButtonGroup title="Actions" className={className}>
      <DropdownItem color={ColorType.SECONDARY} className="epow-button me-2"
        onClick={() => navigate(PURCHASE_ORDERS_FORM_PATH)}>
        <div>
          {intl.formatMessage({id: "purchase_update_orders"})}
        </div>
      </DropdownItem>
    </ButtonGroup>
  )
}

export default PurchaseListActionsDropdown;
