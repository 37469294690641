import {API_CATALOG_ACTIVITY_CUSTOMERS_PATH} from "../constants/routes/RoutePaths"
import {dateUtils} from "../utils/dateUtils"
import {httpService} from "./HttpService"
import {
  CatalogActivityCustomerCsv,
  CatalogActivityCustomerSearchObject,
  SellInformations
} from "../interfaces/CatalogActivityCustomerInterfaces";
import {queryUtils} from "../utils/queryUtils";

function getInfosForActivityCustomer(activityId: string, customerId: string, date: string): Promise<SellInformations> {
  return httpService.get<SellInformations>(`${API_CATALOG_ACTIVITY_CUSTOMERS_PATH}/${activityId}/${customerId}/informations?date=${dateUtils.formatDateYYYYMMDD(dateUtils.parseDate(date))}`)
}

function exportCatalogActivityCustomerCsv(params: CatalogActivityCustomerSearchObject): Promise<Blob> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(null, params)
  return httpService.getReadableStream(`${API_CATALOG_ACTIVITY_CUSTOMERS_PATH}/export${queryParams}`)
}

function sendCsv (file: File): Promise<CatalogActivityCustomerCsv[]> {
  const formData = new FormData()
  formData.append("file", file)
  return httpService.postFormData<CatalogActivityCustomerCsv[]>(`${API_CATALOG_ACTIVITY_CUSTOMERS_PATH}/csv-import`, formData)
}

function importCatalogActivityCustomer(catalogActivityCustomerCsv: CatalogActivityCustomerCsv[]): Promise<CatalogActivityCustomerCsv[]> {
  return httpService.post<CatalogActivityCustomerCsv[]>(`${API_CATALOG_ACTIVITY_CUSTOMERS_PATH}/import`, catalogActivityCustomerCsv)
}

export const catalogActivityCustomerService = Object.freeze({
  getInfosForActivityCustomer,
  exportCatalogActivityCustomerCsv,
  sendCsv,
  importCatalogActivityCustomer
})
