import React, {FunctionComponent} from "react";
import ButtonGroup from "../../button/ButtonGroup";
import {DropdownItem} from "reactstrap";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import {ORDERS_FORM_PATH, ORDERS_IMPORT_PATH} from "../../../../constants/routes/RoutePaths";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";

interface OrderListActionsDropdownProps {
  className?: string,
}

const OrderListActionsDropdown: FunctionComponent<OrderListActionsDropdownProps> = ({
  className = "",
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  return (
    <ButtonGroup title="Actions" className={className}>
      <DropdownItem color={ColorType.SECONDARY} className="epow-button me-2"
        onClick={() => navigate(ORDERS_FORM_PATH)}>
        <div>
          {intl.formatMessage({id: "orders_form_title"})}
        </div>
      </DropdownItem>
      {/*<DropdownItem color={ColorType.SECONDARY} className="epow-button me-2" // Commented out to avoid recette and prod testing
                    onClick={() => navigate(ORDER_ITEMS_FORM_PATH)}>
        <div>
          {intl.formatMessage({id: "order_items_form_title"})}
        </div>
      </DropdownItem>*/}
      <DropdownItem color={ColorType.PRIMARY} className="epow-button me-2"
        onClick={() => navigate(ORDERS_IMPORT_PATH)}>
        <div>
          {intl.formatMessage({id: "order_csv_button"})}
        </div>
      </DropdownItem>
    </ButtonGroup>
  )
}

export default OrderListActionsDropdown;
