import {
  Activity,
  ActivityCustomer,
  ActivityCustomerCatalog,
  ActivityFormData,
  ActivityOption,
  ActivityResourceCatalog,
  ActivitySearchObject,
  ActivitySellingPriceCatalog,
  ActivityShortDTO,
  ActivityTableDTO,
  ActivityWithUsage,
  LinkActivityRequest
} from "../interfaces/ActivityInterfaces";
import {httpService} from "./HttpService";
import {
  API_ACTIVITIES_PATH,
  API_ACTIVITY_CUSTOMERS_PATH,
  API_CATALOG_ACTIVITY_CUSTOMERS_PATH,
  API_CATALOG_ACTIVITY_RESOURCE_CUSTOMERS_PATH,
  API_REFERENCES_PATH
} from "../constants/routes/RoutePaths";
import {ReferenceResponse, Service} from "../interfaces/ServiceInterfaces";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {queryUtils} from "../utils/queryUtils";
import {dateUtils} from "../utils/dateUtils";
import {OrderItemSearchObject} from "../interfaces/OrderItemInterfaces";

function getActivities(): Promise<Activity[]> {
  return httpService.get<Activity[]>(API_ACTIVITIES_PATH)
}

function getActivitiesPage(params: PaginationQueryParams, filters: ActivitySearchObject): Promise<PageResponse<ActivityTableDTO>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filters)
  return httpService.get<PageResponse<Activity>>(`${API_ACTIVITIES_PATH}/page${queryParams}`)
}

function getActivitiesPageShort(params: PaginationQueryParams, filters: ActivitySearchObject): Promise<PageResponse<ActivityShortDTO>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filters)
  return httpService.get<PageResponse<Activity>>(`${API_ACTIVITIES_PATH}/page/short${queryParams}`)
}

function getActivitiesOption(params: PaginationQueryParams, filters: ActivitySearchObject): Promise<PageResponse<ActivityOption>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filters)
  return httpService.get<PageResponse<ActivityOption>>(`${API_ACTIVITIES_PATH}/option${queryParams}`)
}


function getActivity(id: string, orderId?: string): Promise<Activity> {
  return httpService.get<Activity>(`${API_ACTIVITIES_PATH}/${id}${orderId ? `?orderId=${orderId}` : ""}`)
}

function getActivityServices(id: string): Promise<Service[]> {
  return httpService.get<Service[]>(`${API_ACTIVITIES_PATH}/${id}/services`)
}

function addActivityService(id: string, serviceIds: string[]): Promise<Service[]> {
  return httpService.put<Service[]>(`${API_ACTIVITIES_PATH}/${id}/services`, {serviceIds})
}

function deleteActivityService(id: string, serviceId: string): Promise<Response> {
  return httpService.delete(`${API_ACTIVITIES_PATH}/${id}/services/${serviceId}`)
}

function getActivityCustomers(activityId: string): Promise<ActivityCustomerCatalog[]> {
  return httpService.get<ActivityCustomerCatalog[]>(`${API_ACTIVITY_CUSTOMERS_PATH}/${activityId}`)
}

function getReference(activityName: string): Promise<ReferenceResponse>{
  return httpService.get<ReferenceResponse>(`${API_REFERENCES_PATH}${API_ACTIVITIES_PATH}/${activityName}`)
}

function createActivity (activity: ActivityFormData): Promise<Activity> {
  return httpService.post<Activity>(API_ACTIVITIES_PATH, activity)
}

function createActivityCustomer (activityCustomer: ActivityCustomer): Promise<ActivityCustomer> {
  return httpService.post<ActivityCustomer>(API_ACTIVITY_CUSTOMERS_PATH, activityCustomer)
}

function updateActivityCustomer (activityCustomerId: string, paxMaxCustomer: number, billingName: string, bookTicketTourLeader: boolean): Promise<ActivityCustomer> {
  return httpService.put<ActivityCustomer>(`${API_ACTIVITY_CUSTOMERS_PATH}/${activityCustomerId}`,  {paxMaxCustomer, billingName, bookTicketTourLeader})
}

function createActivitySellingPriceCatalog (activitySellingPriceCatalog: ActivitySellingPriceCatalog): Promise<ActivitySellingPriceCatalog> {
  return httpService.post<ActivitySellingPriceCatalog>(API_CATALOG_ACTIVITY_CUSTOMERS_PATH, activitySellingPriceCatalog)
}

function createActivityResourceCatalog (activityResourceCatalog: ActivityResourceCatalog): Promise<ActivityResourceCatalog> {
  return httpService.post<ActivityResourceCatalog>(API_CATALOG_ACTIVITY_RESOURCE_CUSTOMERS_PATH, activityResourceCatalog)
}

function updateActivity (id: string, activity: Activity): Promise<Activity> {
  return httpService.put<Activity>(`${API_ACTIVITIES_PATH}/${id}`, activity)
}

function updateActivitySellingPriceCatalog (id: string, activitySellingPriceCatalog: ActivitySellingPriceCatalog): Promise<ActivitySellingPriceCatalog> {
  return httpService.put<ActivitySellingPriceCatalog>(`${API_CATALOG_ACTIVITY_CUSTOMERS_PATH}/${id}`, activitySellingPriceCatalog)
}

function updateActivityResourceCatalog (id: string, activityResourceCatalog: ActivityResourceCatalog): Promise<ActivityResourceCatalog> {
  return httpService.put<ActivityResourceCatalog>(`${API_CATALOG_ACTIVITY_RESOURCE_CUSTOMERS_PATH}/${id}`, activityResourceCatalog)
}

function deleteActivity (activityId: string): Promise<Response> {
  return httpService.delete(`${API_ACTIVITIES_PATH}/${activityId}`)
}

function deleteActivitySellingPriceCatalog (activitySellingPriceCatalogId: string): Promise<Response> {
  return httpService.delete(`${API_CATALOG_ACTIVITY_CUSTOMERS_PATH}/${activitySellingPriceCatalogId}`)
}

function deleteActivityCustomer (activityCustomerId: string): Promise<Response> {
  return httpService.delete(`${API_ACTIVITY_CUSTOMERS_PATH}/${activityCustomerId}`)
}

function deleteActivityResourceCatalog (activityResourceCatalogId: string): Promise<Response> {
  return httpService.delete(`${API_CATALOG_ACTIVITY_RESOURCE_CUSTOMERS_PATH}/${activityResourceCatalogId}`)
}

function buildActivityFormData (activity?: Activity): ActivityFormData {
  if (!activity) return undefined;

  return {
    id: activity.id,
    name: activity.name,
    reference: activity.reference,
    billingName: activity.billingName,
    duration: dateUtils.convertSecondsToTime(activity.duration),
    numberPaxMax: activity.numberPaxMax,
    staffingManagement: activity.staffingManagement,
    price: activity.price?.toString(),
    activityAreaId: activity.activityArea?.id,
    activityServicesId: activity.activityServicesId,
    description: activity.description,
    forKids: activity.forKids || false,
    unitType: activity.unitType,
    showActivityTourLeader: activity.showActivityTourLeader,
    productTypeId: activity.productType?.id
  }
}

const getActivitySummaryCsv = (params: OrderItemSearchObject): Promise<Blob> => {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(null, params)
  return httpService.getReadableStream(`${API_ACTIVITIES_PATH}/summary${queryParams}`)
}

const getActivitySummaryPdf = (params: OrderItemSearchObject): Promise<Blob> => {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(null, params)
  return httpService.getReadableStream(`${API_ACTIVITIES_PATH}/individual-summary${queryParams}`)
}

function getLinkedActivity(activityId: string): Promise<ActivityShortDTO[]> {
  return httpService.get(`${API_ACTIVITIES_PATH}/links/${activityId}`)
}

function addLinkedActivity(request: LinkActivityRequest): Promise<ActivityShortDTO[]> {
  return httpService.post(`${API_ACTIVITIES_PATH}/links`, request)
}

function deleteLinkedActivity(request: LinkActivityRequest): Promise<Response> {
  return httpService.delete(`${API_ACTIVITIES_PATH}/links`, request)
}

function sendMailNewActivity(): Promise<Response> {
  return httpService.post(`${API_ACTIVITIES_PATH}/send-new-activities`, {})
}

function getActivitiesUsagePage(params: PaginationQueryParams, filterParams: OrderItemSearchObject) {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get<PageResponse<ActivityWithUsage>>(`${API_ACTIVITIES_PATH}/usages/page${queryParams}`)
}

export const activityService = Object.freeze ({
  getActivities,
  getActivitiesPage,
  getActivity,
  getActivityServices,
  addActivityService,
  deleteActivityService,
  getActivityCustomers,
  getReference,
  createActivity,
  createActivityCustomer,
  createActivitySellingPriceCatalog,
  createActivityResourceCatalog,
  updateActivity,
  updateActivitySellingPriceCatalog,
  updateActivityResourceCatalog,
  deleteActivity,
  deleteActivitySellingPriceCatalog,
  deleteActivityResourceCatalog,
  buildActivityFormData,
  getActivitySummaryCsv,
  getActivitySummaryPdf,
  updateActivityCustomer,
  getLinkedActivity,
  addLinkedActivity,
  deleteLinkedActivity,
  deleteActivityCustomer,
  getActivitiesPageShort,
  getActivitiesOption,
  sendMailNewActivity,
  getActivitiesUsagePage
})
