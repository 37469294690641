import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {RouteProps} from "react-router-dom";
import Layout from "../components/layout/Layout";
import PageTitle from "../components/molecules/header/PageTitle";
import {Col} from "reactstrap";
import OrderItemsFormList from "../components/molecules/list/OrderItemsFormList";

const OrderItemFormListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()

  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column epow-order-form">

        <div className="d-flex mb-5">
          <Col xs={12}>
            <PageTitle pageTitle={intl.formatMessage({id: "order_items_form_title"})}></PageTitle>
          </Col>
        </div>

        <OrderItemsFormList />
      </div>
    </Layout>
  )
}

export default OrderItemFormListView;
