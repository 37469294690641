import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {
  Booking,
  BookingByStatusResponse,
  BookingDocumentFormData, BookingFileRequest, BookingItem, BookingPriceResponse,
  BookingSearchObject,
  BookingTableDto, BookingToDoDeleteRequest
} from "../interfaces/BookingInterfaces";
import {SearchFilter} from "../interfaces/api/FiltersInterface";
import {queryUtils} from "../utils/queryUtils";
import {httpService} from "./HttpService";
import {
  API_BOOKINGS_BY_STATUS_PATH,
  API_BOOKINGS_PATH,
  API_BOOKINGS_DOCUMENT_PATH,
  API_BOOKINGS_CANCEL_PATH, API_BOOKINGS_KEEP_PATH
} from "../constants/routes/RoutePaths";
import {PurchaseBookingRequest} from "../interfaces/PurchaseInterfaces";
import {fileUtils} from "../utils/fileUtils";
import {ContentTypeEnum} from "../constants/fileType";
import { DocumentDTO } from "interfaces/FileInterfaces";

function createBooking (booking: PurchaseBookingRequest): Promise<Booking> {
  return httpService.post<Booking>(`${API_BOOKINGS_PATH}`, booking)
}

function getBookingsPage(params: PaginationQueryParams, filterParams: BookingSearchObject): Promise<PageResponse<BookingTableDto>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get<PageResponse<BookingTableDto>>(`${API_BOOKINGS_PATH}${queryParams}`)
}

function getBookingsByStatus(filterParams: SearchFilter): Promise<BookingByStatusResponse> {
  const params = queryUtils.buildFilterParams(filterParams);
  const stringParams = params.length > 0 ? `?${params.join("&")}` : ""
  return httpService.get<BookingByStatusResponse>(`${API_BOOKINGS_BY_STATUS_PATH}${stringParams}`)
}

function updateBooking (id: string, booking: PurchaseBookingRequest): Promise<Booking> {
  return httpService.put<Booking>(`${API_BOOKINGS_PATH}/${id}`, booking)
}

function deleteBooking (id: string): Promise<Response> {
  return httpService.delete(`${API_BOOKINGS_PATH}/${id}`)
}

function addFiles(id: string, file: FileList, request: BookingFileRequest): Promise<DocumentDTO> {
  const formData = fileUtils.createFormDataWithFiles(file);
  formData.set("request", new Blob([JSON.stringify(request)],{type: "application/json"}))
  return httpService.postFormData(`${API_BOOKINGS_PATH}/${id}/files`, formData);
}

function updatePendingBooking (id: string, booking: BookingDocumentFormData): Promise<BookingItem> {
  const formData = fileUtils.createFormDataWithBookingFiles(booking);
  return httpService.putFormData(`${API_BOOKINGS_PATH}/${id}/pending`, formData);
}

function getDocumentById(fileId: string): Promise<Blob> {
  return httpService.getReadableStream(`${API_BOOKINGS_DOCUMENT_PATH}/${fileId}`);
}

function cancelBooking(id: string): Promise<BookingItem> {
  return httpService.put<BookingItem>(`${API_BOOKINGS_CANCEL_PATH}/${id}`, {});
}

function keepBooking(id: string): Promise<BookingItem> {
  return httpService.put<BookingItem>(`${API_BOOKINGS_KEEP_PATH}/${id}`, {});
}


function deleteDocumentById(fileId: string): Promise<Response> {
  return httpService.delete(`${API_BOOKINGS_DOCUMENT_PATH}/${fileId}`)
}

function deleteBookingsToDo (bookingToDoDeleteRequest: BookingToDoDeleteRequest): Promise<Response> {
  return httpService.delete(`${API_BOOKINGS_PATH}/to-do`, bookingToDoDeleteRequest)
}

function generateSepaFile(bookingIds: string[]): Promise<Blob> {
  return httpService.postReadableStream(`${API_BOOKINGS_PATH}/sepa`, {entityIds: bookingIds}, ContentTypeEnum.JSON)
}

function getBookingPriceForSupplier(bookingId: string, supplierId: string) : Promise<BookingPriceResponse> {
  return httpService.get<BookingPriceResponse>(`${API_BOOKINGS_PATH}/${bookingId}/price?supplierId=${supplierId}`)
}

function downloadBookingBills(params: BookingSearchObject): Promise<Blob> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(null, params)
  return httpService.getReadableStream(`${API_BOOKINGS_PATH}/bills${queryParams}`)
}

function updateBookingsMassCatalog(bookingIds: string[]): Promise<Response> {
  return httpService.put(`${API_BOOKINGS_PATH}/mass-catalog`, {bookingIds: bookingIds})
}

export const bookingService = {
  createBooking,
  getBookingsPage,
  updateBooking,
  deleteBooking,
  getBookingsByStatus,
  updatePendingBooking,
  getDocumentById,
  deleteDocumentById,
  cancelBooking,
  keepBooking,
  deleteBookingsToDo,
  generateSepaFile,
  getBookingPriceForSupplier,
  addFiles,
  downloadBookingBills,
  updateBookingsMassCatalog
}
