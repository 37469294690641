import {httpService} from "./HttpService";
import {API_MAIN_CONTACT, API_SUPPLIERS_PATH,} from "../constants/routes/RoutePaths";
import {
  Supplier,
  SupplierDetails,
  SupplierGeneralInfo,
  SupplierRequest,
  SupplierSettings,
} from "interfaces/SupplierInterfaces";
import {Contact} from "interfaces/ContactInterfaces";
import {PageResponse, PaginationQueryParams} from "interfaces/api/PaginationInterface";
import {SearchFilter} from "interfaces/api/FiltersInterface";
import {queryUtils} from "../utils/queryUtils";
import {BookingItem} from "../interfaces/BookingInterfaces";

function getSuppliers(): Promise<Supplier[]> {
  return httpService.get<Supplier[]>(`${API_SUPPLIERS_PATH}`);
}

function getSuppliersPaginated(params: PaginationQueryParams, filterParams: SearchFilter): Promise<PageResponse<SupplierDetails>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams);
  return httpService.get<PageResponse<SupplierDetails>>(`${API_SUPPLIERS_PATH}/page${queryParams}`);
}

function getSupplier(id: string): Promise<Supplier> {
  return httpService.get<Supplier>(`${API_SUPPLIERS_PATH}/${id}`);
}

function getActiveSuppliers (): Promise<Supplier[]> {
  return httpService.get<Supplier[]>(`${API_SUPPLIERS_PATH}/active`)
}

function createSupplier(supplierGeneralInfo: SupplierGeneralInfo): Promise<Supplier> {
  return httpService.post<Supplier>(API_SUPPLIERS_PATH, supplierGeneralInfo);
}

function updateSupplier(id: string, supplier: Supplier): Promise<Supplier> {
  return httpService.put<Supplier>(`${API_SUPPLIERS_PATH}/${id}`, supplier);
}

function updateSupplierGeneralInfo(id: string, supplierRequest: SupplierRequest): Promise<Supplier> {
  return httpService.patch<Supplier>(`${API_SUPPLIERS_PATH}/${id}/general-info`, supplierRequest);
}

function updateSupplierSettings(id: string, supplierSettings: SupplierSettings): Promise<Supplier> {
  return httpService.patch<Supplier>(`${API_SUPPLIERS_PATH}/${id}/settings`, supplierSettings);
}

function getMainContactBySupplierId(supplierId: string): Promise<Contact> {
  return httpService.get<Contact>(`${API_SUPPLIERS_PATH}/${supplierId}/${API_MAIN_CONTACT}`);
}

function deleteSupplier(id: string): Promise<Response> {
  return httpService.delete(`${API_SUPPLIERS_PATH}/${id}`);
}

function getPurchaseForSupplier(id: string): Promise<BookingItem[]> {
  return httpService.get(`${API_SUPPLIERS_PATH}/${id}/bookings/purchases`);
}

export const supplierService = {
  getSuppliers,
  getSuppliersPaginated,
  getSupplier,
  getActiveSuppliers,
  createSupplier,
  updateSupplier,
  updateSupplierGeneralInfo,
  updateSupplierSettings,
  getMainContactBySupplierId,
  deleteSupplier,
  getPurchaseForSupplier,
};
