import React, {FunctionComponent} from "react";
import ButtonGroup from "../../button/ButtonGroup";
import {DropdownItem} from "reactstrap";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import {useIntl} from "react-intl";
import useLoading from "../../../../hooks/useLoading";
import {InvoiceSearchObject} from "../../../../interfaces/InvoiceInterfaces";
import {invoiceService} from "../../../../services/InvoiceService";
import {fileUtils} from "../../../../utils/fileUtils";
import {FileData} from "../../../../interfaces/ResourceInterfaces";

interface InvoiceActionsDropdownProps {
  className?: string,
  searchObject: InvoiceSearchObject
}

const InvoiceActionsDropdown: FunctionComponent<InvoiceActionsDropdownProps> = ({
  className = "",
  searchObject,
}) => {
  const {loading, startLoading, stopLoading} = useLoading()
  const intl = useIntl()

  const exportInvoices = () => {
    startLoading();
    const fileData: FileData = {name: "export-invoices", extension: "csv"}
    fileUtils.downloadFile(
      invoiceService.exportInvoices(searchObject),
      fileData,
      intl,
      () => stopLoading()
    )
  }

  return (<>
      <ButtonGroup title="Actions" className={className} loading={loading}>
        <DropdownItem color={ColorType.PRIMARY} className="epow-button me-2"
                      onClick={() => exportInvoices()}>
          <div>
            {intl.formatMessage({id: "invoice_dropdown_export_visible"})}
          </div>
        </DropdownItem>
      </ButtonGroup>
    </>
  )
}

export default InvoiceActionsDropdown;
