import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {AdminConfigurationDto} from "../../../../interfaces/AdminConfigurationInterfaces";
import {adminConfigurationService} from "../../../../services/AdminConfigurationService";
import {toastUtils} from "../../../../utils/toastUtils";
import Button from "../../../atoms/Button";
import AdminConfigurationForm from "../../form/admin/AdminConfigurationForm";
import CardField from "../CardField";
import {dateUtils} from "../../../../utils/dateUtils";

interface AdminConfigurationCardProps {
  className?: string,
}

const AdminConfigurationCard: FunctionComponent<AdminConfigurationCardProps> = ({
  className = "",
}) => {
  const intl = useIntl()
  const [editing, setEditing] = useState<boolean>(false)
  const [adminConfiguration, setAdminConfiguration] = useState<AdminConfigurationDto>({})

  const getAdminConfiguration = () => {
    adminConfigurationService.getAdminConfiguration()
      .then((response) => {
        setAdminConfiguration(response)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_admin_config"}))
      })
  }

  useEffect(() => {
    getAdminConfiguration()
  }, [])

  const onUpdate = () => {
    setEditing(false)
    getAdminConfiguration()
  }

  return (
    <div className={className}>
      {editing ? (
        <AdminConfigurationForm onUpdate={onUpdate} onCancel={() => setEditing(false)} adminConfiguration={adminConfiguration} />
      ) : (
        <>
          <CardField
            label={intl.formatMessage({id: "admin_config_dashboard_booking_todo"})}
            popover="admin_config_dashboard_booking_todo_tooltip"
            value={adminConfiguration.dashboardBookingTodoWindow?.toString()}
          />

          <CardField
            label={intl.formatMessage({id: "admin_config_dashboard_booking_pending"})}
            popover="admin_config_dashboard_booking_pending_tooltip"
            value={adminConfiguration.dashboardBookingPendingWindow?.toString()}
          />

          <CardField
            label={intl.formatMessage({id: "admin_config_dashboard_staffing"})}
            popover="admin_config_dashboard_staffing_tooltip"
            value={adminConfiguration.dashboardStaffingWindow?.toString()}
          />

          <CardField
            label={intl.formatMessage({id: "admin_config_booking_prebooking_delay"})}
            popover="admin_config_booking_prebooking_delay_tooltip"
            value={adminConfiguration.bookingPreBookingDelay?.toString()}
          />

          <CardField
            label={intl.formatMessage({id: "admin_config_booking_service_delay"})}
            popover="admin_config_booking_service_delay_tooltip"
            value={adminConfiguration.bookingServiceDelay?.toString()}
          />

          <CardField
            label={intl.formatMessage({id: "admin_config_booking_delta_minutes"})}
            popover="admin_config_booking_delta_minutes_tooltip"
            value={adminConfiguration.departureTimeMinuteDelta}
          />

          <CardField
            label={intl.formatMessage({id: "admin_config_accounting_period"})}
            value={dateUtils.formatDateDayJs(adminConfiguration.accountingPeriodStartDate) + " - " + dateUtils.formatDateDayJs(adminConfiguration.accountingPeriodEndDate)}
          />

          <Button onClick={() => setEditing(true)} >
            <FormattedMessage id="edit_button" />
          </Button>
        </>
      )}
    </div>
  )
}

export default AdminConfigurationCard;
