import React, {FunctionComponent, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import GenericModal from "./GenericModal";
import {OrderItemDefaultSelectable, OrderItemWithDefaultsRequest} from "../../../interfaces/OrderItemInterfaces";
import CheckboxFilter from "../filters/CheckboxFilter";
import {Formik, FormikProps, FormikValues} from "formik";
import EpowForm from "../form/EpowForm";
import {moneyUtils} from "../../../utils/moneyUtils";
import {orderItemService} from "../../../services/OrderItemService";
import {toast} from "react-toastify";

interface ModalModifyOrderItemsProps {
  open: boolean,
  onCancel: () => void,
  onValidate: () => void,
  datas: OrderItemDefaultSelectable[]
}

const ModalModifyOrderItems: FunctionComponent<ModalModifyOrderItemsProps> = ({
  open,
  onValidate,
  onCancel,
  datas,
}) => {
  const [toggleDescriptionAll, setToggleDescriptionAll] = useState(true)
  const [togglePaxPriceAll, setTogglePaxPriceAll] = useState(true)
  const intl = useIntl()
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  const validateButton = intl.formatMessage({id: "validate_button"})
  const cancelButton = intl.formatMessage({id: "cancel_button"})

  const initialValues = datas.map(data => ({
    ...data,
    descriptionChanged: true,
    defaultDescription: data.dayByDayDescription,
    paxPriceChanged: true,
    defaultPaxPrice: data.catalogPrice,
  }))

  const handleSubmit = (values: FormikValues) => {
    const requestBody: OrderItemWithDefaultsRequest[] = values.map(value => ({
      orderItemId: value.id,
      descriptionChanged: value.descriptionChanged,
      defaultDescription: value.defaultDescription,
      paxPriceChanged: value.paxPriceChanged,
      defaultPaxPrice: value.defaultPaxPrice
    }))

    orderItemService.updateOrderItemsWithDefault(requestBody)
      .then(() => toast.success(intl.formatMessage({id: "order_form_applied"})))
      .catch(() => toast.error(intl.formatMessage({id: "order_form_error"})))
  }

  return (
    <GenericModal
      open={open}
      className="p-3"
      title={intl.formatMessage({id:"order_items_form_title"})}
      footer={{
        primaryAction: {
          buttonLabel: validateButton,
          action: () => formikRef.current?.submitForm()
        },
        secondaryAction: {
          buttonLabel: cancelButton,
          action: onCancel
        }
      }}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={(values) => {
          handleSubmit(values)
          onValidate();
        }}
      >
        {({values, setFieldValue}) => (
          <EpowForm>
            <p><FormattedMessage id="order_items_modify_modal_info"/></p>
            <div className="d-flex justify-content-end">
              <div>
                <CheckboxFilter
                  title={intl.formatMessage({id: "order_item_description"})}
                  value={toggleDescriptionAll}
                  onChange={(value) => {
                    setToggleDescriptionAll(value)
                    values.forEach((_, index) => setFieldValue(`[${index}].descriptionChanged`, value))
                  }}
                />
                <CheckboxFilter
                  title={intl.formatMessage({id: "billing_item_unit_price"})}
                  value={togglePaxPriceAll}
                  onChange={(value) => {
                    setTogglePaxPriceAll(value)
                    values.forEach((_, index) => setFieldValue(`[${index}].paxPriceChanged`, value))
                  }}
                />
              </div>
            </div>
            {values.map((orderItem, index) => (
              <div className="d-flex align-items-center justify-content-between border-top" key={index}>
                <div className="w-75 my-2">
                  <p className="fw-bold">{orderItem.name} | {orderItem.order.customerReference}</p>
                  <div className={`${orderItem.descriptionChanged ? "color-black" : "color-gray"}`}>
                    <p className="fw-bold">{orderItem.dayByDayDescription || "-"}</p>
                    <p className="text-decoration-line-through">{orderItem.description || "-"}</p>
                  </div>
                  <p className={`${orderItem.paxPriceChanged ? "color-black" : "color-gray"}`}>
                    {" "}<span className="fw-bold">{moneyUtils.formatNumberToCurrency(orderItem.catalogPrice)}</span>
                    {" ("}<span className="text-decoration-line-through">{moneyUtils.formatNumberToCurrency(orderItem.paxPrice)}</span>)
                  </p>
                </div>
                <div className="w-25 ms-1">
                  <CheckboxFilter
                    title={intl.formatMessage({id: "order_item_description"})}
                    value={orderItem.descriptionChanged}
                    onChange={(value) => setFieldValue(`[${index}].descriptionChanged`, value)}
                  />
                  <CheckboxFilter
                    title={intl.formatMessage({id: "billing_item_unit_price"})}
                    value={orderItem.paxPriceChanged}
                    onChange={(value) => setFieldValue(`[${index}].paxPriceChanged`, value)}
                  />
                </div>
              </div>
            ))}
          </EpowForm>
        )}
      </Formik>
    </GenericModal>
  )
}

export default ModalModifyOrderItems
