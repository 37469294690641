import {httpService} from "./HttpService";
import {API_ORDER_ITEMS_PATH, API_RESOURCES_PATH} from "../constants/routes/RoutePaths";
import {
  OrderItem,
  OrderItemCancellationRequest,
  OrderItemDetailsResponse,
  OrderItemFileRequest,
  OrderItemRequest,
  OrderItemResponse,
  OrderItemSearchObject,
  OrderItemStaffingPageItemDto,
  OrderItemStaffingStatus,
  OrderItemWithDefaultDto,
  OrderItemWithDefaultsRequest,
} from "../interfaces/OrderItemInterfaces";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {queryUtils} from "../utils/queryUtils";
import {StaffedResource} from "../interfaces/ActivityStaffingInterface";
import {DocumentDTO} from "../interfaces/FileInterfaces";
import {fileUtils} from "../utils/fileUtils";
import {Booking} from "../interfaces/BookingInterfaces";
import {ResourceStaffingRequest, ResourceStaffingSearchParams} from "../interfaces/ResourceStaffingInterfaces";

function getOrderItems(filter?: {filter: string}): Promise<OrderItem[]> {
    return httpService.get<OrderItem[]>(API_ORDER_ITEMS_PATH, filter)
}

function getOrderItemsPage(params: PaginationQueryParams, filterParams: OrderItemSearchObject): Promise<PageResponse<OrderItemResponse>> {
    const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
    return httpService.get<PageResponse<OrderItemResponse>>(`${API_ORDER_ITEMS_PATH}/page${queryParams}`)
}

function getOrderItemsStaffingPage(params: PaginationQueryParams, filterParams: OrderItemSearchObject): Promise<PageResponse<OrderItemStaffingPageItemDto>> {
    const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
    return httpService.get<PageResponse<OrderItemStaffingPageItemDto>>(`${API_ORDER_ITEMS_PATH}/staffing/page${queryParams}`)
}

function getOrderItem(id: string): Promise<OrderItemDetailsResponse> {
    return httpService.get<OrderItemDetailsResponse>(`${API_ORDER_ITEMS_PATH}/${id}`)
}

function getOrderItemForResource(id: string): Promise<OrderItemDetailsResponse> {
    return httpService.get<OrderItemDetailsResponse>(`${API_RESOURCES_PATH}/self${API_ORDER_ITEMS_PATH}/${id}`)
}

function updateOrderItem (id: string, orderItem: OrderItemRequest): Promise<OrderItem> {
    return httpService.put<OrderItem>(`${API_ORDER_ITEMS_PATH}/${id}`, orderItem)
}

function deleteOrderItem (id: string): Promise<Response> {
    return httpService.delete(`${API_ORDER_ITEMS_PATH}/${id}`)
}

function cancelOrderItem (id: string, request: OrderItemCancellationRequest): Promise<OrderItemResponse> {
    return httpService.put(`${API_ORDER_ITEMS_PATH}/${id}/cancel`, request)
}

function createOrderItem (orderItemRequest: OrderItemRequest): Promise<OrderItem> {
    return httpService.post<OrderItem>(API_ORDER_ITEMS_PATH, orderItemRequest)
}

function getOrderItemsWithDefault(params: PaginationQueryParams, filterParams: OrderItemSearchObject): Promise<PageResponse<OrderItemWithDefaultDto>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get<PageResponse<OrderItemWithDefaultDto>>(`${API_ORDER_ITEMS_PATH}/activity/page${queryParams}`)
}

function updateOrderItemsWithDefault(orderItems: OrderItemWithDefaultsRequest[]): Promise<OrderItemResponse[]> {
  return httpService.put<OrderItemResponse[]>(`${API_ORDER_ITEMS_PATH}/default`, orderItems)
}

function getOrderItemStaffing(id: string): Promise<StaffedResource[]> {
    return httpService.get(`${API_ORDER_ITEMS_PATH}/${id}/staffing`)
}

function sendStaffingRequestForOrderItem(id: string, request: ResourceStaffingRequest[]): Promise<OrderItemStaffingPageItemDto> {
    return httpService.post<OrderItemStaffingPageItemDto>(`${API_ORDER_ITEMS_PATH}/${id}/staffing`, request)
}

function sendStaffingRequestForMultipleOrderItems(orderItemIds: string[], searchParams: ResourceStaffingSearchParams): Promise<void> {
    return httpService.post<void>(`${API_ORDER_ITEMS_PATH}/staffing`, {orderItemIds, searchParams})
}

function sendStaffingSelectionRequestForMultipleOrderItems(orderItemIds: string[], staffingRequest: ResourceStaffingRequest[]): Promise<void> {
    return httpService.post<void>(`${API_ORDER_ITEMS_PATH}/multiple-staffing`, {orderItemIds, staffingRequest})
}

function sendDirectStaffingForOrderItem(orderItemId: string, resourceIds: string[]): Promise<OrderItemStaffingPageItemDto> {
    return httpService.post<OrderItemStaffingPageItemDto>(`${API_ORDER_ITEMS_PATH}/${orderItemId}/direct-staffing`, resourceIds)
}

function updateStaffingForOrderItem(orderItemId: string, staffingId: string, request: ResourceStaffingRequest): Promise<StaffedResource> {
    return httpService.put<StaffedResource>(`${API_ORDER_ITEMS_PATH}/${orderItemId}/staffing/${staffingId}`, request)
}

function deleteStaffingForOrderItem(orderItemId: string, staffingId: string): Promise<Response> {
    return httpService.delete(`${API_ORDER_ITEMS_PATH}/${orderItemId}/staffing/${staffingId}`)
}

function deleteStaffingAndChangeOrderItemStatus(orderItemId: string, staffingId: string, orderItemStatus: OrderItemStaffingStatus): Promise<Response> {
    return httpService.delete(`${API_ORDER_ITEMS_PATH}/${orderItemId}/staffing/${staffingId}/${orderItemStatus}`)
}

function addFiles(orderItemId: string, file: FileList, request: OrderItemFileRequest): Promise<DocumentDTO> {
    const formData = fileUtils.createFormDataWithFiles(file);
    formData.set("request", new Blob([JSON.stringify(request)],{type: "application/json"}))
    return httpService.postFormData(`${API_ORDER_ITEMS_PATH}/${orderItemId}/files`, formData);
}

function createBookingsForOrderItem(orderItemId: string): Promise<Booking[]> {
    return httpService.post(`${API_ORDER_ITEMS_PATH}/${orderItemId}/booking`, {})
}

function deleteDocumentById(fileId: string): Promise<Response> {
    return httpService.delete(`${API_ORDER_ITEMS_PATH}/document/${fileId}`)
}

function removeVoucherFromOrderItem(fileId: string, content: string, orderItemId: string): Promise<Response> {
    return httpService.put(`${API_ORDER_ITEMS_PATH}/${orderItemId}/voucher/${fileId}`, content)
}

function validateVoucherForOrderItem(orderItemId: string): Promise<Response> {
  return httpService.put(`${API_ORDER_ITEMS_PATH}/${orderItemId}/voucher/validate`, {})
}

export const orderItemService = Object.freeze({
    getOrderItems,
    getOrderItemsPage,
    getOrderItemsStaffingPage,
    getOrderItem,
    getOrderItemForResource,
    updateOrderItem,
    deleteOrderItem,
    cancelOrderItem,
    createOrderItem,
    getOrderItemStaffing,
    sendStaffingRequestForOrderItem,
    sendStaffingRequestForMultipleOrderItems,
    sendStaffingSelectionRequestForMultipleOrderItems,
    sendDirectStaffingForOrderItem,
    updateStaffingForOrderItem,
    deleteStaffingForOrderItem,
    deleteStaffingAndChangeOrderItemStatus,
    addFiles,
    createBookingsForOrderItem,
    deleteDocumentById,
    removeVoucherFromOrderItem,
    validateVoucherForOrderItem,
    getOrderItemsWithDefault,
    updateOrderItemsWithDefault
})
