import React, {FunctionComponent} from "react";
import {Col} from "reactstrap";
import {AccountingInvoiceList} from "../../../../interfaces/InvoiceInterfaces";
import {moneyUtils} from "../../../../utils/moneyUtils";
import BillingTableRow from "./BillingTableRow";
import Icon from "../../../icon/Icon";
import {optionUtils} from "../../../../utils/optionUtils";
import {CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS} from "../../../../constants/CustomerConstants";
import {useIntl} from "react-intl";

interface BillingByCustomerZoneTableRowProps {
  className?: string,
  invoicesByCustomerZone: AccountingInvoiceList,
  openRow?: {[index: string]: boolean},
  setOpenRow?: (open: {[index: string]: boolean}) => void,
}

const BillingByCustomerZoneTableRow: FunctionComponent<BillingByCustomerZoneTableRowProps> = ({
  className = "",
  invoicesByCustomerZone,
  openRow,
  setOpenRow,
}) => {
  const intl = useIntl();

  const handleOpenRow = (customerZoneId: string) => {
    const isRowCurrentlyOpen = openRow[customerZoneId] ?? false

    setOpenRow({
      ...openRow,
      [customerZoneId]: !isRowCurrentlyOpen,
    })
  }


  return (
    <div  className="epow-custom-table-row">
      <div onClick={() => handleOpenRow(invoicesByCustomerZone.id)} className={`epow-custom-table-row-main ${(className) ?? ""}`} style={{backgroundColor: "lightblue"}}>
        <Col className="col-15 fw-bold">
          {optionUtils.translateOption(intl, CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS, invoicesByCustomerZone?.geographicalZone).toUpperCase()}
        </Col>

        <Col className="col-8">
          {moneyUtils.formatNumberToCurrency(invoicesByCustomerZone?.totalAmountHT)}
        </Col>
        <Col>
          {openRow[invoicesByCustomerZone.id] ? (
            <Icon name="ChevronUp" />
          ) : (
            <Icon className="mb-1" name="ChevronDown" />
          )}
        </Col>
      </div>
      {openRow[invoicesByCustomerZone?.id] && invoicesByCustomerZone.invoices.length > 0 &&
        invoicesByCustomerZone.invoices.map((invoice, index) => (
          (
            <BillingTableRow key={index} invoice={invoice}/>
          )
        ))
      }
    </div>
  )
}

export default BillingByCustomerZoneTableRow;
