import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import AdminExportServicesCsvCard from "../admin/export/AdminExportServicesCsvCard";
import NextTasks from "../../dashboard/NextTasks";
import {ActivityBillingStatus} from "../../../../interfaces/ActivityBllingInterface";
import {useIntl} from "react-intl";
import {DashboardAccountantCounts} from "../../../../interfaces/DashboardInterfaces";
import {BILLING_OVERVIEW_PATH, BOOKINGS_PATH, INVOICES_PATH} from "../../../../constants/routes/RoutePaths";
import {useNavigate} from "react-router-dom";
import {activityBillingService} from "../../../../services/ActivityBillingService";
import {dateUtils} from "../../../../utils/dateUtils";
import {bookingService} from "../../../../services/BookingService";
import {BookingStatus} from "../../../../interfaces/BookingInterfaces";
import {invoiceService} from "../../../../services/InvoiceService";
import {InvoiceState} from "../../../../interfaces/InvoiceInterfaces";
import {DateFormats} from "../../../../constants/DateConstants";
import {moneyUtils} from "../../../../utils/moneyUtils";
import {AdminConfigurationAccountingDto} from "../../../../interfaces/AdminConfigurationInterfaces";
import {adminConfigurationService} from "../../../../services/AdminConfigurationService";
import {toastUtils} from "../../../../utils/toastUtils";

interface DashboardAccountantProps {
  className?: string,
}

interface Periode {
  startDate: string,
  endDate: string

}
const DashboardAccountant: FunctionComponent<DashboardAccountantProps> = ({
  className = "",
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [counters, setCounters] = React.useState<DashboardAccountantCounts>({
    resourceBillsPaid: "0",
    bookingsClosed: "0",
    invoiceHTLastMonth: 0,
    invoiceHTCurrentMonth: 0,
    invoiceHTPeriodWithoutDisbursement: 0,
  })
  const [adminConfiguration, setAdminConfiguration] = useState<AdminConfigurationAccountingDto>(null)

  useEffect(() => {
    adminConfigurationService.getAdminAccountingPeriod()
      .then((response) => setAdminConfiguration(response))
      .catch(() => {
        setAdminConfiguration(null)
        toastUtils.errorToast(intl.formatMessage({id: "dashboard_error_get_admin_configuration"}))
      })
  }, []);

  const buildFilterPeriod = (): Periode => {
    return {
      startDate: dateUtils.formatDateYYYYMMDD(dateUtils.firstDayOfTheMonth()),
      endDate: dateUtils.formatDateYYYYMMDD(dateUtils.lastDayOfTheMonth())
    }
  }

  const buildFilterPeriodLastMonth = () :Periode => {
    return {
      startDate: dateUtils.formatDateYYYYMMDD(dateUtils.firstDayOfLastMonth()),
      endDate: dateUtils.formatDateYYYYMMDD(dateUtils.lastDayOfLastMonth())
    }
  }

  const buildFilterAccountingPeriod = (): Periode => {
    return {
      startDate: adminConfiguration?.accountingPeriodStartDate,
      endDate: adminConfiguration?.accountingPeriodEndDate
    }
  }

  const redirectToResourceBills = (status: ActivityBillingStatus) => {
    const {startDate, endDate} = buildFilterPeriod()
    navigate(`${BILLING_OVERVIEW_PATH}?status=${status}&startDate=${startDate}&endDate=${endDate}`)
  }

  const redirectToBookingsClosed = () => {
    const {startDate, endDate} = buildFilterPeriod()
    const statuses = [BookingStatus.CLOSED]
    navigate(`${BOOKINGS_PATH}?startDate=${startDate}&endDate=${endDate}&status=${statuses}&displayClosed=true`)
  }

  const redirectToInvoicesWithPeriod = (getPeriod: () => Periode) => {
    const { startDate, endDate } = getPeriod();
    navigate(`${INVOICES_PATH}?startDate=${startDate}&endDate=${endDate}&status=${[InvoiceState.BILLED, InvoiceState.LATE, InvoiceState.PAID]}`);
  };

  const getResourceBillsPaid = async (status: ActivityBillingStatus): Promise<string> => {
    return await activityBillingService.getActivityBillingPage({pageSize: 1}, {status, ...buildFilterPeriod()})
      .then((response) => response.totalElements)
      .catch(() => null)
  }

  const getBookingsClosed = async (): Promise<string> => {
    return await bookingService.getBookingsPage({pageSize: 1}, {...buildFilterPeriod(), status: [BookingStatus.CLOSED]})
      .then(response => response.totalElements)
      .catch(() => null)
  }

  const getInvoicesHT = async (startDate: string, endDate: string, withDisbursement: boolean): Promise<number> => {
    if (!startDate || !endDate) return
    return await invoiceService.getInvoicesHT({pageSize: 1}, {status: [InvoiceState.BILLED, InvoiceState.LATE, InvoiceState.PAID], startDate, endDate, withDisbursement: withDisbursement})
      .then((res) => res.totalHT)
      .catch(() => null)
  }

  useMemo(() => {
    const loadData = async () => {
      setCounters({
        resourceBillsPaid: await getResourceBillsPaid(ActivityBillingStatus.SETTLED) ?? "0",
        bookingsClosed: await getBookingsClosed() ?? "0",
        invoiceHTLastMonth: await getInvoicesHT(buildFilterPeriodLastMonth().startDate, buildFilterPeriodLastMonth().endDate, true) ?? 0,
        invoiceHTCurrentMonth: await getInvoicesHT(buildFilterPeriod().startDate, buildFilterPeriod().endDate, true) ?? 0,
        invoiceHTPeriodWithoutDisbursement: await getInvoicesHT(buildFilterAccountingPeriod().startDate, buildFilterAccountingPeriod().endDate, false) ?? 0,
      })
    }

    loadData().catch(() => null)
  }, [adminConfiguration]);


  return (
    <div className={className}>
      <NextTasks
        size="next-task-200"
        title={intl.formatMessage({id: "dashboard_accounting_title"})}
        tasks={[
          {title: intl.formatMessage({id: "dashboard_tasks_resource_bills_paid"}), icon: "Search", number: counters.resourceBillsPaid, onClick: () => redirectToResourceBills(ActivityBillingStatus.SETTLED)},
          {title: intl.formatMessage({id: "dashboard_tasks_purchase_closed"}), icon: "Search", number: counters.bookingsClosed, onClick: () => redirectToBookingsClosed()},
          {
            title: intl.formatMessage({
                id: "dashboard_accounting_ca_month"
              },
              {
                date: dateUtils.formatDateDayJs(
                  dateUtils.formatDateYYYYMMDD(
                    dateUtils.firstDayOfLastMonth()), DateFormats.monthAndYear, DateFormats.apiDate).toString()
              }
            ),
            icon: "Receipt", number: moneyUtils.formatNumberToCurrency(counters.invoiceHTLastMonth), onClick: () => redirectToInvoicesWithPeriod(buildFilterPeriodLastMonth),
            iconColor:"primary"
          },
          {
            title:
              intl.formatMessage({
                  id: "dashboard_accounting_ca_month"
                },
                {
                  date: dateUtils.formatDateDayJs(
                    dateUtils.formatDateYYYYMMDD(
                      dateUtils.firstDayOfTheMonth()), DateFormats.monthAndYear, DateFormats.apiDate).toString()
                }
              ), icon: "Receipt", number: moneyUtils.formatNumberToCurrency(counters.invoiceHTCurrentMonth), onClick: () => redirectToInvoicesWithPeriod(buildFilterPeriod)
          },
          {
            title:
              intl.formatMessage({
                  id: "dashboard_accounting_cumulative_ca"
                }
              ),
            icon: "Receipt",
            number: moneyUtils.formatNumberToCurrency(counters.invoiceHTPeriodWithoutDisbursement),
            onClick: () => redirectToInvoicesWithPeriod(buildFilterAccountingPeriod),
            iconColor: "orange"
          },
        ]}
      />
      <AdminExportServicesCsvCard className="epow-card-admin-export"/>
    </div>
  )
}

export default DashboardAccountant;
