import {API_BLACK_DATE_PATH,} from "constants/routes/RoutePaths";
import {httpService} from "./HttpService";
import {BlackDate, BlackDateSearchObject, CreateBlackDate} from "../interfaces/BlackDateInterface";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {queryUtils} from "../utils/queryUtils";

function create(blackDate: CreateBlackDate): Promise<BlackDate> {
    return httpService.post<BlackDate>(
        `${API_BLACK_DATE_PATH}`,
        blackDate
    );
}

function findAll(params: PaginationQueryParams, filterParams: BlackDateSearchObject): Promise<PageResponse<BlackDate>> {
    const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
    return httpService.get<PageResponse<BlackDate>>(
        `${API_BLACK_DATE_PATH}${queryParams}`
    );
}

function _delete(blackDateId: string[]): Promise<Response> {
    return httpService.delete(`${API_BLACK_DATE_PATH}`, blackDateId);
}

export const blackDateService = {
    findAll,
    create,
    delete: _delete
}
